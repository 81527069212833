import {
    IonContent,
    IonPage,
    IonButton,
    IonInput,
    IonAlert,
} from "@ionic/react";
import React, {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";
import api from "../api";
import axios from "axios";
import {loadingController} from "@ionic/core";
import ReCAPTCHA from "react-google-recaptcha";
import helper from "../helper";

import {useTranslation} from "react-i18next";

interface VerifyBarcode {
    location: any;
}

const VerifyBarcode: React.FC<VerifyBarcode> = ({location}) => {
    const [tokenVerified, setTokenVerified] = useState<boolean>(false);
    const [serialNumber, setSerialNumber] = useState<string>("");
    const [openAlert, setOpenAlert] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const {t, i18n} = useTranslation();

    const history = useHistory();

    useEffect(() => {
        if (location.state) {
            setSerialNumber(location.state.barcode);
        }
    }, [])

    function getConfig() {
        return {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        };
    }

    function verifyCaptcha(captchaToken: string) {
        let bodyFormData = new FormData();
        bodyFormData.set("token", captchaToken);

        axios.post(api.verifyRecaptcha, bodyFormData, getConfig()).then((res) => {
            console.log(res);
            if (res.data.google_response.success) setTokenVerified(true);
        });
    }

    async function submit() {
        const loading = await loadingController.create({
            message: "Please wait...",
        });
        loading.present();
        let bodyFormData = new FormData();
        bodyFormData.set("serialNumber", serialNumber);
        axios.post(api.verifyBarcode, bodyFormData, getConfig()).then((res) => {
            loading.dismiss().then(() => {
                if (!res.data.success) {
                    console.log(res.data.errorMessage);

                    let errorMessage = "";
                    if (res.data.errorMessage.includes("else")) {
                        errorMessage = t("barcode_was_already_scanned_else");
                    } else if (res.data.errorMessage.includes("Invalid")) {
                        errorMessage = t("invalid_product");
                    } else errorMessage = t("barcode_was_already_scanned");
                    setErrorMessage(
                        errorMessage + "<br/><br/>" + t("type_barcode_number") + "<br />" + t("click_cancel")
                    );
                    setOpenAlert(true);
                } else {
                    helper.showToast("", "");
                    history.push("home");
                }
            });
            console.log(res);
        });
    }

    return (
        <IonPage dir={i18n.language === "IL" ? "rtl" : "ltr"}>
            <IonContent className="main-container main-page-content">
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        submit();
                    }}
                >
                    <IonInput
                        className="custom-input"
                        type="number"
                        placeholder={t("enter_serial_number")}
                        value={location.state ? location.state.barcode : serialNumber}
                        required
                        onIonChange={(e) => {
                            location.state = null;
                            setSerialNumber(e.detail.value!);
                        }}
                    />
                    <div className="recapcha-wrapper">
                        <ReCAPTCHA
                            onChange={(res) => verifyCaptcha(res!)}
                            sitekey={localStorage.getItem("captcha")!}
                        />
                    </div>
                    <IonButton
                        fill="clear"
                        className="secondary-btn"
                        onClick={() => history.goBack()}
                    >
                        {t("go_back")}
                    </IonButton>
                    <IonButton
                        className="custom-btn"
                        type="submit"
                        disabled={!tokenVerified}
                    >
                        {t("submit")}
                    </IonButton>
                </form>
            </IonContent>
            <IonAlert
                isOpen={openAlert}
                onDidDismiss={() => setOpenAlert(false)}
                message={errorMessage}
                buttons={[
                    {
                        text: t("cancel"),
                        role: "cancel",
                        cssClass: "secondary",
                    },
                    {
                        text: t("confirm"),
                        handler: () => {
                            history.push("/support", {
                                type: "Visonic product",
                                supportText: serialNumber
                                    ? serialNumber
                                    : location.state.barcode + " text",
                            });
                        },
                    },
                ]}
            />
        </IonPage>
    );
};

export default VerifyBarcode;
