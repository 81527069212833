import {
  IonCard,
  IonCardContent,
  IonSpinner,
  IonCol,
  IonCardTitle,
  IonCardSubtitle,
  IonModal,
  IonButton,
} from "@ionic/react";
import React, { useState, useEffect, useRef, useCallback } from "react";
import api from "../api";
import axios from "axios";

interface ContainerProps {}

const TipItemComponent: React.FC<ContainerProps> = () => {
  interface Item {
    id: string;
    description: string;
    date: Date;
    contentUrl: string;
    imageUrl: string;
  }
  const [items, setItems] = useState(Array<Item>());

  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);

  const [showImage, setShowImage] = useState(false);
  const [openedImageId, setOpenedImageId] = useState("");

  const observer: any = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setCurrentPage((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  function getConfig(limit: number, page: number) {
    return {
      params: {
        limit: limit,
        page: page,
      },
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
  }
  useEffect(() => {
    console.log(showImage);
  }, [showImage]);

  useEffect(() => {
    setLoading(true);
    axios.get(api.tipKnowledgeItems, getConfig(10, currentPage)).then((res) => {
      console.log(res.data);
      setLoading(false);
      setHasMore(res.data.results.length > 0);
      setItems(items.concat(res.data.results));
    });
  }, [currentPage]);

  return (
    <>
      {items.map((item, index) => {
        if (items.length === index + 1) {
          return (
            <IonCol
              className="knowledge-item"
              size="12"
              size-sm="6"
              size-md="4"
              key={item.id}
            >
              <IonCard ref={lastBookElementRef} className="knowledge-card">
                <div
                  className="card-media"
                  onClick={() => {
                    setShowImage(true);
                    setOpenedImageId(item.id);
                  }}
                >
                  <img src={item.imageUrl} alt="" />
                  <div className="how-to-card-hover card-hover">
                    <span className="card-hover-label">Read more</span>
                  </div>
                </div>

                <IonCardContent>
                  <IonCardTitle className="knowledge-card-title">
                    {item.description}
                  </IonCardTitle>
                  <IonCardSubtitle className="knowledge-card-subtitle">
                    {item.date}
                  </IonCardSubtitle>
                </IonCardContent>
              </IonCard>
              {openedImageId === item.id && (
                <IonModal
                  cssClass="knowledge-modal"
                  isOpen={showImage}
                  backdropDismiss
                  onDidDismiss={() => setShowImage(false)}
                >
                  <a
                    href={item.contentUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={item.imageUrl} alt="" />
                  </a>
                  <IonButton
                    className="close-modal"
                    onClick={() => setShowImage(false)}
                  ></IonButton>
                </IonModal>
              )}
            </IonCol>
          );
        } else
          return (
            <IonCol
              className="knowledge-item"
              size="12"
              size-sm="6"
              size-md="4"
              key={item.id}
            >
              <IonCard className="knowledge-card">
                <div
                  className="card-media"
                  onClick={() => {
                    setShowImage(true);
                    setOpenedImageId(item.id);
                  }}
                >
                  <img src={item.imageUrl} alt="" className="" />
                  <div className="how-to-card-hover card-hover">
                    <span className="card-hover-label">Read more</span>
                  </div>
                </div>
                <IonCardContent>
                  <IonCardTitle className="knowledge-card-title">
                    {item.description}
                  </IonCardTitle>
                  <IonCardSubtitle className="knowledge-card-subtitle">
                    {item.date}
                  </IonCardSubtitle>
                </IonCardContent>
              </IonCard>
              {openedImageId === item.id && (
                <IonModal
                  cssClass="knowledge-modal"
                  isOpen={showImage}
                  backdropDismiss
                  onDidDismiss={() => setShowImage(false)}
                >
                  <a
                    href={item.contentUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={item.imageUrl} alt="" />
                    <div className="how-to-card-hover card-hover">
                    <span className="card-hover-label">Read more</span>
                  </div>
                  </a>
                  <IonButton
                    className="close-modal"
                    onClick={() => setShowImage(false)}
                  ></IonButton>
                </IonModal>
              )}
            </IonCol>
          );
      })}
      {loading && <IonSpinner name="crescent" />}
    </>
  );
};

export default TipItemComponent;
