import React from "react";
import { IonList, IonItem, IonButton } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

interface ChangeLangList {
  closeLangModal?: any;
}
const ChangeLangList: React.FC<ChangeLangList> = ({ closeLangModal }) => {
  const { i18n } = useTranslation();
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };
  const history = useHistory();

  if (closeLangModal)
    return (
      <IonList className="mobile-menu-list mobile-country-menu-list change-branch-list">
        <IonItem>
          <IonButton
            onClick={() => {
              changeLanguage("UK");
              closeLangModal();
            }}
          >
            EN
          </IonButton>
        </IonItem>
        <IonItem>
          <IonButton
            onClick={() => {
              changeLanguage("IL");
              closeLangModal();
            }}
          >
            HE
          </IonButton>
        </IonItem>
        <IonItem>
          <IonButton
            onClick={() => {
              changeLanguage("FR");
              closeLangModal();
            }}
          >
            FR
          </IonButton>
        </IonItem>
        <IonItem>
          <IonButton
            onClick={() => {
              changeLanguage("SP");
              closeLangModal();
            }}
          >
            SP
          </IonButton>
        </IonItem>
        <IonItem>
          <IonButton
            onClick={() => {
              changeLanguage("PT");
              closeLangModal();
            }}
          >
            PT
          </IonButton>
        </IonItem>

        {/* <IonItem>
          <IonButton>SA</IonButton>
        </IonItem>
        <IonItem>
          <IonButton>NL</IonButton>
        </IonItem> 
        <IonItem>
          <IonButton
            onClick={() => {
              changeLanguage("RO");
              closeLangModal();
            }}
          >
            RO
          </IonButton>
        </IonItem>
        */}
      </IonList>
    );
  return (
    <IonList className="mobile-menu-list mobile-country-menu-list change-branch-list">
      <IonItem>
        <IonButton
          onClick={() => {
            changeLanguage("UK");
            history.goBack();
          }}
        >
          EN
        </IonButton>
      </IonItem>
      <IonItem>
        <IonButton
          onClick={() => {
            changeLanguage("FR");
            history.goBack();
          }}
        >
          FR
        </IonButton>
      </IonItem>
      <IonItem>
        <IonButton
          onClick={() => {
            changeLanguage("IL");
            history.goBack();
          }}
        >
          HE
        </IonButton>
      </IonItem>
      <IonItem>
        <IonButton
          onClick={() => {
            changeLanguage("SP");
            history.goBack();
          }}
        >
          SP
        </IonButton>
      </IonItem>

      <IonItem>
        <IonButton
          onClick={() => {
            changeLanguage("PT");
            history.goBack();
          }}
        >
          PT
        </IonButton>
      </IonItem>
      {/* <IonItem>
        <IonButton>SA</IonButton>
      </IonItem>
      <IonItem>
        <IonButton>NL</IonButton>
      </IonItem> */}
      {/* <IonItem>
        <IonButton
          onClick={() => {
            changeLanguage("RO");
            history.goBack();
          }}
        >
          RO
        </IonButton>
      </IonItem> */}
    </IonList>
  );
};

export default ChangeLangList;
