import {
  IonInput,
  IonItem,
  IonLabel,
  IonButton,
  IonDatetime,
  IonText,
  IonPage,
  IonHeader,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import { chevronBack } from "ionicons/icons";
import api from "../api";
import axios from "axios";
import { Plugins } from "@capacitor/core";
import helper from "../helper";
import { loadingController } from "@ionic/core";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import FooterDesktop from "../components/FooterDesktop";

const UpdateProfile: React.FC = () => {
  const [accountType, setAccountType] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [company, setCompany] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [addressLine1, setAddressLine1] = useState<string>("");
  const [addressLine2, setAddressLine2] = useState<string>("");
  const [postalCode, setPostalCode] = useState<string>("");
  const [branch, setBranch] = useState<string>("");
  const { t, i18n } = useTranslation();
  const [validationErrors, setValidationErrors] = useState<any>([]);

  const [userId, setUserId] = useState<string>();

  const history = useHistory();

  const { Network } = Plugins;

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("user")!);
    let name = user.name.split(" ");
    console.log(user);
    try {
      setFirstName(name[0]);
      setLastName(name[1]);
      setCompany(user.company);
      setPhone(user.phone);
      setEmail(user.email);
      setSelectedDate(user.dateOfBirth);
      setCountry(user.country);
      setState(user.state);
      setCity(user.city);
      setAddressLine1(user.addressLine1);
      setAddressLine2(user.addressLine2);
      setPostalCode(user.postalCode);
      setUserId(user.id);
      setAccountType(user.customerType);
      setBranch(user.branch.name);
    } catch (error) {
      console.log(error.message);
    }
  }, []);

  Network.addListener("networkStatusChange", (status) => {
    console.log("Network status changed", status);
    if (status.connected) {
      let userUpdateInfo = localStorage.getItem("userUpdateInfo");
      if (userUpdateInfo) {
        updateUser(JSON.parse(userUpdateInfo));
        localStorage.removeItem("userUpdateInfo");
      }
    }
  });

  let status = async () => await Network.getStatus();

  function getConfig() {
    return {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
  }

  const handleSubmit = async () => {
    const user = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone,
      dateOfBirth: selectedDate,
      addressLine1: addressLine1,
      addressLine2: addressLine2,
      country: country,
      postalCode: postalCode,
      customerType: accountType,
      city: city,
      state: state,
      company: company,
    };
    
    status().then(function (value) {
      if (value.connected) {
        updateUser(user);
      } else {
        localStorage.setItem("userUpdateInfo", JSON.stringify(user));
      }
    });
  };

  async function updateUser(value: object) {
    const loading = await loadingController.create({
      message: "Please wait...",
    });
    loading.present();
    axios.put(api.updateUser + userId, value, getConfig()).then((res) => {
      console.log(res);
      console.log(res.data);

      if (res.data.success) {
        localStorage.setItem("user", JSON.stringify(res.data.user));
        history.goBack();
      }

      loading.dismiss().then(() => {
        if (!res.data.success)
          helper.showAlert(res.data.success, res.data.errorMessage);
      });
    });
  }

  function handleFormValidation(event: any) {
    event.preventDefault();
    const errors = [];

    if (firstName === null || firstName.trim() === "") {
      errors.push(t("validation.firstName_empty"));
    }
    if (lastName === null || lastName.trim() === "") {
      errors.push(t("validation.lastName_empty"));
    }
    if (company === null || company.trim() === "") {
      errors.push(t("validation.company_empty"));
    }

    if (phone === null || phone.trim() === "") {
      errors.push(t("validation.phone_empty"));
    }
    if (country === null || country.trim() === "") {
      errors.push(t("validation.country_empty"));
    }
    if (state === null || state.trim() === "") {
      errors.push(t("validation.state_empty"));
    }
    if (addressLine1 === null || addressLine1.trim() === "") {
      errors.push(t("validation.address_line_1_empty"));
    }
    if (city === null || city.trim() === "") {
      errors.push(t("validation.city_empty"));
    }
    if (postalCode === null || postalCode.trim() === "") {
      errors.push(t("validation.postal_code_empty"));
    }
    console.log("errors arr", errors);
    if (errors.length) {
      setValidationErrors([]);
      setValidationErrors(errors);
    } else {
      setValidationErrors([]);
      console.log("send form");
      handleSubmit();
    }
  }

  return (
    <>
      <IonPage
        className="main-page-content"
        dir={i18n.language === "IL" ? "rtl" : "ltr"}
      >
        <IonHeader className="back-header">
          <IonButton
            fill="clear"
            className="back-button"
            onClick={() => history.goBack()}
          >
            <IonIcon icon={chevronBack}></IonIcon>
            {t("back")}
          </IonButton>
        </IonHeader>
        <IonGrid className="container update-profile-grid">
          <IonRow>
            <IonCol>
              {validationErrors.length ? (
                <div className="form-errors-block">
                  <ul>
                    {validationErrors.map((item: any, index: number) => {
                      return <li key={index}>{item}</li>;
                    })}
                  </ul>
                </div>
              ) : null}
              <form action="" onSubmit={(event) => handleFormValidation(event)}>
                <IonItem className="select-box field-wrapper">
                  <IonLabel className="select-box-label">{t("i_am")}</IonLabel>
                  <IonInput
                    value={accountType}
                    className="custom-input custom-field"
                    disabled
                  ></IonInput>
                </IonItem>
                <IonItem className="select-box field-wrapper">
                  <IonLabel className="select-box-label">
                    {t("branch")}
                  </IonLabel>
                  <IonInput
                    value={branch}
                    className="custom-input custom-field"
                    disabled
                  ></IonInput>
                </IonItem>
                <IonItem className="field-wrapper">
                  <IonInput
                    type="text"
                    value={firstName}
                    placeholder={t("first_name")}
                    onIonChange={(e) => setFirstName(e.detail.value!)}
                    className="custom-input custom-field"
                  ></IonInput>
                </IonItem>

                <IonItem className="field-wrapper">
                  <IonInput
                    type="text"
                    value={lastName}
                    placeholder={t("last_name")}
                    onIonChange={(e) => setLastName(e.detail.value!)}
                    className="custom-input custom-field"
                  ></IonInput>
                </IonItem>

                <IonItem className="field-wrapper">
                  <IonInput
                    type="text"
                    value={company}
                    placeholder={t("company")}
                    onIonChange={(e) => setCompany(e.detail.value!)}
                    className="custom-input custom-field"
                  ></IonInput>
                </IonItem>

                <IonItem className="field-wrapper">
                  <IonInput
                    type="number"
                    value={phone}
                    placeholder={t("phone")}
                    onIonChange={(e) => setPhone(e.detail.value!)}
                    className="custom-input custom-field"
                  ></IonInput>
                </IonItem>

                <IonItem className="field-wrapper">
                  <IonInput
                    disabled
                    type="email"
                    value={email}
                    placeholder={t("email")}
                    onIonChange={(e) => setEmail(e.detail.value!)}
                    className="custom-input custom-field"
                  ></IonInput>
                </IonItem>

                <IonItem className="field-wrapper date-field-wrapper">
                  <IonText>
                    <h4>{t("date_of_birth")}</h4>
                  </IonText>
                  <IonDatetime
                    className="button-select-custom button-date-custom"
                    displayFormat="MM/DD/YYYY"
                    min="1944-03-14"
                    max="2012-12-09"
                    doneText="Confirm"
                    value={selectedDate}
                    onIonChange={(e) => setSelectedDate(e.detail.value!)}
                  ></IonDatetime>
                </IonItem>

                <IonItem className="field-wrapper small-margin">
                  <IonInput
                    type="text"
                    value={country}
                    placeholder={t("country")}
                    onIonChange={(e) => setCountry(e.detail.value!)}
                    className="custom-input custom-field"
                  ></IonInput>
                </IonItem>

                <IonItem className="field-wrapper small-margin">
                  <IonInput
                    type="text"
                    value={state}
                    placeholder={t("state_province")}
                    onIonChange={(e) => setState(e.detail.value!)}
                    className="custom-input custom-field"
                  ></IonInput>
                </IonItem>

                <IonItem className="field-wrapper small-margin">
                  <IonInput
                    type="text"
                    value={addressLine1}
                    placeholder={t("address_l_1")}
                    onIonChange={(e) => setAddressLine1(e.detail.value!)}
                    className="custom-input custom-field"
                  ></IonInput>
                </IonItem>

                <IonItem className="field-wrapper small-margin">
                  <IonInput
                    type="text"
                    value={addressLine2}
                    placeholder={t("address_l_2")}
                    onIonChange={(e) => setAddressLine2(e.detail.value!)}
                    className="custom-input custom-field"
                  ></IonInput>
                </IonItem>

                <IonItem className="field-wrapper small-margin">
                  <IonInput
                    type="text"
                    value={city}
                    placeholder={t("city")}
                    onIonChange={(e) => setCity(e.detail.value!)}
                    className="custom-input custom-field"
                  ></IonInput>
                </IonItem>

                <IonItem className="field-wrapper small-margin">
                  <IonInput
                    type="text"
                    value={postalCode}
                    placeholder={t("postal_code")}
                    onIonChange={(e) => setPostalCode(e.detail.value!)}
                    required={true}
                    className="custom-input custom-field"
                  ></IonInput>
                </IonItem>
                <IonButton
                  // onClick={handleFormValidation}
                  type="submit"
                  fill="solid"
                  shape="round"
                  className="custom-btn"
                  // type="submit"
                >
                  {t("update")}
                </IonButton>
              </form>
            </IonCol>
          </IonRow>
        </IonGrid>
        <FooterDesktop />
      </IonPage>
    </>
  );
};

export default UpdateProfile;
