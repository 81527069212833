import React, {useState, useEffect, useRef, useContext} from "react";
import axios from "axios";
import moment from "moment";
import {
    IonPage,
    IonSlides,
    IonSlide,
    IonGrid,
    IonRow,
    IonCol,
    IonModal,
    IonButton,
    IonText,
} from "@ionic/react";
import PersonalMessage from "../components/PersonalMessage";
import {useTranslation} from "react-i18next";
import {Link, useParams} from "react-router-dom";
import Tooltip from "react-tooltip-lite";
import MyModal from "../components/MyModal";

import api from "../api";
import helper from "../helper";
import FooterDesktop from "../components/FooterDesktop";
import {BranchContext} from "../context/BranchContext";

interface Installation {
    id: string;
    productName: string;
    productPoints: number;
    date: Date;
    productNameDescription: string;
}

interface Gift {
    id: string;
    name: string;
    imageUrl: string;
    description: string;
    points: number;
}

const Home = () => {
    const [showCard, setShowCard] = useState(false);
    const [pointsToUpgrade, setPointsToUpgrade] = useState(0);
    const [scoreBalance, setScoreBalance] = useState(0);
    const [totalScore, setTotalScore] = useState(0);
    const [pointsTokeepLevel, setPointsToKeepLevel] = useState(0);
    const [installations, setInstallations] = useState(Array<Installation>());
    const [availableGifts, setAvailableGifts] = useState(Array<Gift>());
    const [memberLevel, setMemberLevel] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [showedItem, setShowedItem] = useState<number>();

    const [userName, setUserName] = useState("");
    const [userDate, setUserDate] = useState("");
    const [cardImage, setCardImage] = useState("");

    const {data, dispatch} = useContext(BranchContext)!;
    const UPDATE_BRANCH = "UPDATE_BRANCH";

    const {t, i18n} = useTranslation();
    const slideOptsLeftDir = {
        loop: false,
        initialSlide: 1,
    };

    const slides = useRef(null);

    useEffect(() => {
        let params = window.location.search;
        if (params) {
            const urlParams = new URLSearchParams(params);
            const branch = urlParams.get("branch");
            if (branch) {
                dispatch({
                    type: UPDATE_BRANCH,
                    branch: branch === "IL" ? "IL" : branch,
                });
            }
        }

        document.title = t("page_titles.home");
    }, []);

    function getConfig() {
        return {
            params: {
                limit: 5,
                page: 1,
            },
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        };
    }

    useEffect(() => {
        const savedUser = JSON.parse(localStorage.getItem("user")!);
        if (savedUser) {
            setUserName(savedUser.name);
            setUserDate(savedUser.levelUpgradeValidDate);
            setMemberLevel(savedUser.memberLevel);
        }

        const config = {
            params: {
                email: savedUser.email,
                password: savedUser.password,
                branch: savedUser.branch.name,
            },
        };

        const getUser = () => {
            axios.get(api.login, config).then((res) => {
                console.log(res);
                if (res.data.success && res.data.user) {
                    setPointsToUpgrade(res.data.user.pointsToUpgrade);
                    setScoreBalance(res.data.user.scoreBalance);
                    setTotalScore(res.data.user.totalScore);
                    setInstallations(res.data.user.installations.slice(0, 4));
                    setMemberLevel(res.data.user.memberLevel);
                    setPointsToKeepLevel(res.data.user.levelPointsToKeepLevel);
                    localStorage.setItem("user", JSON.stringify(res.data.user));
                }
            });
        };

        const getAvailableGifts = () => {
            axios.get(api.getAvailableGifts, getConfig()).then((res) => {
                if (res && res.data.success && res.data.results.length > 0) {
                    console.log(res);
                    setAvailableGifts(res.data.results);
                }
            });
        };

        getUser();
        getAvailableGifts();
    }, []);


    useEffect(() => {
        if (memberLevel !== "") {
            setCardImage(getCardImage());
        }
    }, [memberLevel]);

    const getCardImage = () => {
        const branch = data.branch;
        let img = "";

        switch (branch) {
            case "UK":
                if (memberLevel === "Gold")
                    img = "img/cards/UK/VClub-Cards-Valid-Gold-EN.png";
                else if (memberLevel === "Diamond")
                    img = "img/cards/UK/VisoniClub_diamond_card_en_without_name.jpg";
                else if (memberLevel === "Platinum")
                    img = "img/cards/UK/VClub-Cards-Valid-Platinum-EN.png";
                else if (memberLevel === "Silver")
                    img = "img/cards/UK/VClub-Cards-Valid-Silver-EN.png";
                else img = "img/cards/new_card.png";
                break;
            case "FR":
                if (memberLevel === "Gold")
                    img = "img/cards/FR/VClub-Cards-Valid-Gold-FR.png";
                else if (memberLevel === "Diamond")
                    img = "img/cards/FR/VisoniClub_diamond_card_fr_without_name.jpg";
                else if (memberLevel === "Platinum")
                    img = "img/cards/FR/VClub-Cards-Valid-Platinum-FR.png";
                else if (memberLevel === "Silver")
                    img = "img/cards/FR/VClub-Cards-Valid-Silver-FR.png";
                else img = "img/cards/new_card.png";
                break;
            case "IL":
                if (memberLevel === "Gold")
                    img = "img/cards/Heb/VClub-Cards-Valid-Gold-Heb.png";
                else if (memberLevel === "Diamond")
                    img = "img/cards/Heb/VisoniClub_diamond_card_he_without_name.jpg";
                else if (memberLevel === "Platinum")
                    img = "img/cards/Heb/VClub-Cards-Valid-Platinum-Heb.png";
                else if (memberLevel === "Silver")
                    img = "img/cards/Heb/VClub-Cards-Valid-Silver-Heb.png";
                else img = "img/cards/new_card.png";
                break;
            case "SP":
                if (memberLevel === "Gold")
                    img = "img/cards/SP/VClub-Cards-Valid-Gold-SP.png";
                else if (memberLevel === "Diamond")
                    img = "img/cards/SP/VisoniClub_diamond_card_es_without_name.jpg";
                else if (memberLevel === "Platinum")
                    img = "img/cards/SP/VClub-Cards-Valid-Platinum-SP.png";
                else if (memberLevel === "Silver")
                    img = "img/cards/SP/VClub-Cards-Valid-Silver-SP.png";
                else img = "img/cards/new_card.png";
                break;
            case "PT":
                if (memberLevel === "Gold")
                    img = "img/cards/SP/VClub-Cards-Valid-Gold-SP.png";
                else if (memberLevel === "Diamond")
                    img = "img/cards/SP/VisoniClub_diamond_card_es_without_name.jpg";
                else if (memberLevel === "Platinum")
                    img = "img/cards/SP/VClub-Cards-Valid-Platinum-SP.png";
                else if (memberLevel === "Silver")
                    img = "img/cards/SP/VClub-Cards-Valid-Silver-SP.png";
                else img = "img/cards/new_card.png";
                break;
            default:
                if (memberLevel === "Gold")
                    img = "img/cards/SP/VClub-Cards-Valid-Gold-SP.png";
                else if (memberLevel === "Diamond")
                    img = "img/cards/SP/VisoniClub_diamond_card_es_without_name.jpg";
                else if (memberLevel === "Platinum")
                    img = "img/cards/SP/VClub-Cards-Valid-Platinum-SP.png";
                else if (memberLevel === "Silver")
                    img = "img/cards/SP/VClub-Cards-Valid-Silver-SP.png";
                else img = "img/cards/new_card.png";
                break;
        }
        return img;
    };

    const handleClick = (gift: Gift, index: number) => {
        setShowedItem(index);
        if (gift.points > scoreBalance)
            helper.showAlert(false, "You have not enough points");
        else setShowModal(true);
    };

    function recalculatePoints(points: number) {
        setScoreBalance(points);
    }


    return (
        <>
            <IonPage dir={i18n.language === "IL" ? "rtl" : "ltr"}>
                <IonModal isOpen={showCard} cssClass="card-modal">
                    <div
                        className={
                            memberLevel === "Gold"
                                ? "card-wrapper gold-card-wrapper"
                                : memberLevel === "Diamond"
                                ? "card-wrapper diamond-card-wrapper"
                                : memberLevel === "Platinum"
                                    ? "card-wrapper platinum-card-wrapper"
                                    : memberLevel === "Silver"
                                        ? "card-wrapper silver-card-wrapper"
                                        : "card-wrapper card-wrapper-default"
                        }
                    >
                        <img className="modal-img" src={cardImage} alt=""/>
                        <span className="card-name">{userName}</span>
                        <span className="card-date">{userDate}</span>
                    </div>

                    <IonButton
                        className="close-modal"
                        onClick={() => setShowCard(false)}
                    />
                </IonModal>
                <IonSlides
                    ref={slides}
                    className="home-slider"
                    pager={true}
                    options={slideOptsLeftDir}
                >
                    <IonSlide className="first-slide">
                        <div
                            className={
                                i18n.language === "IL"
                                    ? "slide-content orientation-right"
                                    : "slide-content"
                            }
                        >
                            <div className="slide-content-block">
                                <div className="block-left">
                                    <div className="heading">{t("points_to_use.text")}:</div>
                                    <div
                                        className={
                                            scoreBalance.toString().length >= 4 ? "num-small" : "num"
                                        }
                                    >
                                        {scoreBalance}
                                    </div>
                                </div>
                                <div className="block-right">
                                    <div className="heading">{t("you_have_gained.text")}:</div>
                                    <div className="num">{totalScore ? totalScore : 'asdasd'}</div>
                                    <div className="heading">{t("points_to_upgrade.text")}:</div>
                                    <div className="num">{pointsToUpgrade}</div>
                                    <div className="heading">
                                        {t("points_needed_to_keep_your_level")}:
                                    </div>
                                    <div className="num">{pointsTokeepLevel}</div>
                                </div>
                            </div>
                        </div>
                    </IonSlide>
                    <IonSlide>
                        <IonButton className="slide-card" onClick={() => setShowCard(true)}>
                            <div
                                className={
                                    memberLevel === "Gold"
                                        ? "card-wrapper gold-card-wrapper"
                                        : memberLevel === "Diamond"
                                        ? "card-wrapper diamond-card-wrapper"
                                        : memberLevel === "Platinum"
                                            ? "card-wrapper platinum-card-wrapper"
                                            : memberLevel === "Silver"
                                                ? "card-wrapper silver-card-wrapper"
                                                : "card-wrapper card-wrapper-default"
                                }
                            >
                                <img className="modal-img" src={cardImage} alt=""/>
                                <span className="card-name">{userName}</span>
                                <span className="card-date">{userDate}</span>
                            </div>
                            <span className="card-button"></span>
                        </IonButton>
                    </IonSlide>
                </IonSlides>
                <IonGrid className="main-block container">
                    <PersonalMessage/>
                    <IonRow className="main-links ion-hide-sm-up">
                        <IonCol className="main-item" size="6">
                            <Link to="/my_points">
                                <img src="img/main_links/main-code-ico.png" alt=""/>
                                <p>{t("my_points")}</p>
                            </Link>
                        </IonCol>
                        <IonCol className="main-item" size="6">
                            <Link to="/redeem">
                                <img src="img/main_links/main-present-ico.png" alt=""/>
                                <p>{t("redeem")}</p>
                            </Link>
                        </IonCol>
                        <IonCol className="main-item" size="6">
                            <a
                                href="http://catalog.visonic.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img src="img/main_links/main-book-ico.png" alt=""/>
                                <p>{t("online_catalog")}</p>
                            </a>
                        </IonCol>
                        <IonCol className="main-item" size="6">
                            <Link to="/knowledge_center">
                                <img src="img/main_links/main-cap-ico.png" alt=""/>
                                <p>{t("knowledge_center")}</p>
                            </Link>
                        </IonCol>
                        <IonCol className="main-item" size="12">
                            <Link to="/support">
                                <img src="img/main_links/main-headphones-ico.png" alt=""/>
                                <p>{t("support")}</p>
                            </Link>
                        </IonCol>
                    </IonRow>
                </IonGrid>

                <IonGrid className="container home-desktop-data">
                    <IonRow className="home_status-update">
                        <IonCol>
                            <IonRow>
                                <IonCol>
                                    <IonText>
                                        <h2 className="home-section-title">{t("status_update")}</h2>
                                    </IonText>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol
                                    className="status-update-cert status-update-box"
                                    size="6"
                                    size-xl="4"
                                    size-md="3"
                                >
                                    <div
                                        className={
                                            memberLevel === "Gold"
                                                ? "card-wrapper gold-card-wrapper"
                                                : memberLevel === "Diamond"
                                                ? "card-wrapper diamond-card-wrapper"
                                                : memberLevel === "Platinum"
                                                    ? "card-wrapper platinum-card-wrapper"
                                                    : memberLevel === "Silver"
                                                        ? "card-wrapper silver-card-wrapper"
                                                        : "card-wrapper card-wrapper-default"
                                        }
                                    >
                                        <img className="modal-img" src={cardImage} alt=""/>
                                        <span className="card-name">{userName}</span>
                                        <span className="card-date">{userDate}</span>
                                    </div>
                                </IonCol>
                                <IonCol className="status-update-box" size="6" size-md="2">
                                    <div className="box-heading">
                                        <h4>{t("points_to_use.text")}:</h4>
                                        <div className="tooltip">
                                            <Tooltip content={t("points_to_use.tooltip")}>
                                                <span className="tooltip-pic">?</span>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div className="num">{scoreBalance}</div>
                                </IonCol>
                                <IonCol className="status-update-box" size="6" size-md="2">
                                    <div className="box-heading">
                                        <h4>{t("you_have_gained.text")}:</h4>
                                        <Tooltip content={t("you_have_gained.tooltip")}>
                                            <span className="tooltip-pic">?</span>
                                        </Tooltip>
                                    </div>
                                    <div className="num">{totalScore}</div>
                                </IonCol>
                                <IonCol className="status-update-box" size="6" size-md="2">
                                    <div className="box-heading">
                                        <h4>{t("points_to_upgrade.text")}:</h4>
                                        <Tooltip content={t("points_to_upgrade.tooltip")}>
                                            <span className="tooltip-pic">?</span>
                                        </Tooltip>
                                    </div>
                                    <div className="num">{pointsToUpgrade}</div>
                                </IonCol>
                                <IonCol className="status-update-box" size="12" size-md="2">
                                    <div className="box-heading">
                                        <h4>{t("points_needed_to_keep_your_level")}:</h4>
                                        <Tooltip content={t("points_needed_to_keep_your_level")}>
                                            <span className="tooltip-pic">?</span>
                                        </Tooltip>
                                    </div>
                                    <div className="num">{pointsTokeepLevel}</div>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                    <IonRow className="home_my-points">
                        <IonCol>
                            <IonRow>
                                <IonCol>
                                    <IonText className="home-section-head">
                                        <h2 className="home-section-title">{t("my_points")}</h2>
                                        <Link to="/my_points" className="view-all-link">
                                            {t("view_all")}
                                        </Link>
                                    </IonText>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonGrid className="points-table">
                                        <IonRow className="header-table-row points-table-row">
                                            <IonCol size="6">{t("product")}</IonCol>
                                            <IonCol size="4">{t("date")}</IonCol>
                                            <IonCol size="2">{t("points")}</IonCol>
                                        </IonRow>
                                        {installations &&
                                        installations.length > 0 &&
                                        installations.map((item) => {
                                            return (
                                                <IonRow
                                                    key={item.id}
                                                    className="body-table-row points-table-row"
                                                >
                                                    <IonCol size="6">
                                                        {item.productNameDescription}
                                                    </IonCol>
                                                    <IonCol size="4">
                                                        {moment(item.date).format("DD/MM/YYYY")}
                                                    </IonCol>
                                                    <IonCol size="2" className="points-col">
                                                        {item.productPoints}
                                                    </IonCol>
                                                </IonRow>
                                            );
                                        })}
                                    </IonGrid>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                    <IonRow className="home_buy">
                        <IonCol>
                            <IonRow>
                                <IonCol>
                                    <IonText className="home-section-head">
                                        <h2 className="home-section-title">{t("what_can_buy")}</h2>
                                        <Link to="/redeem" className="view-all-link">
                                            {t("view_all")}
                                        </Link>
                                    </IonText>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                {availableGifts &&
                                availableGifts.length > 0 &&
                                availableGifts.map((gift, index) => {
                                    return (
                                        <IonCol
                                            key={gift.id}
                                            className="item-wrapper"
                                            size="12"
                                            size-sm="6"
                                            size-md="3"
                                            size-lg="1"
                                        >
                                            <div className="item">
                                                <div className="num-points">
                                                    <span>{gift.points}</span>&nbsp;{t("points")}
                                                </div>
                                                <div
                                                    className="plus-link"
                                                    onClick={() => handleClick(gift, index)}
                                                >
                                                    <span className="plus-link-icon">+</span>
                                                </div>
                                                <img src={gift.imageUrl} alt=""/>
                                            </div>
                                            <h5 className="name">{gift.name}</h5>
                                            {showedItem === index && (
                                                <MyModal
                                                    showModal={showModal}
                                                    item={gift}
                                                    hideModal={() => setShowModal(false)}
                                                    recalculatePoints={(points: number) =>
                                                        recalculatePoints(points)
                                                    }
                                                />
                                            )}
                                        </IonCol>
                                    );
                                })}
                            </IonRow>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <FooterDesktop/>
            </IonPage>
        </>
    );
};

export default Home;
