import React, { useState, useCallback } from "react";
import {
  IonTitle,
  IonToolbar,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonButton,
  IonText,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  IonAlert,
  IonModal,
} from "@ionic/react";
import { mail } from "ionicons/icons";

import api from "../api";
import axios from "axios";
import helper from "../helper";
import { loadingController } from "@ionic/core";
import { useDropzone } from "react-dropzone";
import PersonalMessage from "../components/PersonalMessage";
import { useTranslation } from "react-i18next";
import FooterDesktop from "../components/FooterDesktop";
import BackToolbar from "../components/BackToolbar";

interface SupportPage {
  location: any;
}

const SupportPage: React.FC<SupportPage> = ({ location }) => {
  const [text, setText] = useState<string>("");
  const [file, setFile] = useState("");
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>("");

  const [supportType, setSupportType] = useState<string>("");
  // const [contentField, setcontentField] = useState<string>("");

  const [validationErrors, setValidationErrors] = useState<any>([]);

  const { t, i18n } = useTranslation();

  const onDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0]);
  }, []);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  const files = acceptedFiles.map((file: any) => (
    <span className="choosen-file-name" key={file.path}>
      {file.path}
    </span>
  ));

  function getConfig() {
    return {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
  }

  const submit = async () => {
    const loading = await loadingController.create({
      message: "Please wait...",
    });

    let bodyFormData = new FormData();
    bodyFormData.set("content", text);
    bodyFormData.set("type", supportType);
    bodyFormData.set("attachment", file);

    loading.present();
    axios.post(api.supportCase, bodyFormData, getConfig()).then((res) => {
      console.log(res);
      loading
        .dismiss()
        .then(() => {
          // helper.showAlertSupport(
          //   res.data.success,
          //   res.data.errorMessage,
          //   t("success")
          // );
          setSuccessMessage(t("success"));
          setOpenAlert(true);
          if (res.data.success) {
            setSupportType("");
            setText("");
            setFile("");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    });
  };

  function handleFormValidation(e: any) {
    e.preventDefault();
    const errors = [];
    if (supportType.trim() === "") {
      errors.push(t("validation.support_type"));
    }
    // if (contentField.trim() === "") {
    //   errors.push(t("validation.content"));
    // }
    if (errors.length) {
      setValidationErrors([]);
      setValidationErrors(errors);
    } else {
      setValidationErrors([]);
      console.log("send form");
      submit();
    }
  }

  return (
    <>
      <IonPage
        className="support-page main-page-content"
        dir={i18n.language === "IL" ? "rtl" : "ltr"}
      >
        <BackToolbar />
        <IonGrid className="container">
          <PersonalMessage />
          <IonToolbar className="title-toolbar">
            <IonTitle className="page-title">{t("support")}</IonTitle>
          </IonToolbar>
          <IonRow>
            <IonCol>
              <IonText className="text-center send-us-text">
                <IonIcon className="title-icon" icon={mail}></IonIcon>
                {t("send_us_email")}
              </IonText>
              {validationErrors.length ? (
                <div className="form-errors-block">
                  <ul>
                    {validationErrors.map((item: any, index: number) => {
                      return <li key={index}>{item}</li>;
                    })}
                  </ul>
                </div>
              ) : null}
              <form
                onSubmit={(e) => {
                  handleFormValidation(e);
                  // submit();
                }}
              >
                <IonSelect
                  interface="popover"
                  value={location.state ? location.state.type : supportType}
                  placeholder={t("select_type")}
                  onIonChange={(e) => setSupportType(e.detail.value)}
                  className="support-field support-select custom-select"
                >
                  <IonSelectOption value="Visonic club question">
                    {t("support_type_select.visonic_question")}
                  </IonSelectOption>
                  <IonSelectOption value="Visonic product">
                    {t("support_type_select.visonic_product")}
                  </IonSelectOption>
                </IonSelect>

                <IonTextarea
                  rows={4}
                  className="support-field custom-textarea"
                  value={location.state ? location.state.supportText : text}
                  onIonChange={(e) => setText(e.detail.value!)}
                  placeholder={t("content")}
                  required
                ></IonTextarea>
                <div className="or-call">{t("or_call")}: 08707300830</div>
                <section className="choose-file-section">
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <IonButton className="upload-button">
                      {t("choose_file")}
                    </IonButton>
                  </div>
                  <aside className="files-names-block">
                    <div>{files}</div>
                  </aside>
                </section>
                <div
                  className={
                    i18n.language === "IL"
                      ? "support-nav-buttons orientation-right"
                      : "support-nav-buttons"
                  }
                >
                  {/* <IonButton
                  fill="clear"
                  className="secondary-btn"
                  onClick={() => {
                    history.goBack();
                  }}
                > */}
                  <IonButton className="custom-btn submit-button" type="submit">
                    {t("submit")}
                  </IonButton>
                </div>
              </form>
            </IonCol>
          </IonRow>
        </IonGrid>
        {/* <IonAler
          isOpen={openAlert}
          onDidDismiss={() => setOpenAlert(false)}
          message={successMessage}
          buttons={[
            {
              text: t("cancel"),
              role: "cancel",
              cssClass: "secondary",
            },
          ]}
        /> */}
        <IonModal isOpen={openAlert} cssClass="support-modal">
          <p>{successMessage}</p>
          <IonButton
            className="close-modal-btn"
            onClick={() => setOpenAlert(false)}
          >
            X
          </IonButton>
        </IonModal>
        <FooterDesktop />
      </IonPage>
    </>
  );
};

export default SupportPage;
