import {
  IonPage,
  IonToolbar,
  IonTitle,
  IonSegment,
  IonSegmentButton,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import HowToItemComponent from "../components/HowToItemComponent";
import TipItemComponent from "../components/TipItemComponent";
import PersonalMessage from "../components/PersonalMessage";

import { useTranslation } from "react-i18next";
import FooterDesktop from "../components/FooterDesktop";

const KnowledgeItems: React.FC = () => {
  const [itemType, setItemType] = useState<string>("howto");
  const { t, i18n } = useTranslation();

  useEffect(() => {
    document.title = t("page_titles.knowledge_center");
  }, [t]);

  return (
    <>
      <IonPage
        className="main-page-content"
        dir={i18n.language === "IL" ? "rtl" : "ltr"}
      >
        <IonGrid className="container">
          <PersonalMessage />

          <IonRow>
            <IonCol>
              <IonToolbar className="title-toolbar">
                <IonTitle className="page-title">
                  {t("knowledge_center")}
                </IonTitle>
              </IonToolbar>
              <IonToolbar className="segment-toolbar">
                <IonSegment
                  value={itemType}
                  onIonChange={(e) => setItemType(e.detail.value!)}
                >
                  <IonSegmentButton
                    className="segment-btn"
                    defaultChecked={true}
                    value="howto"
                  >
                    {t("how_to")}
                  </IonSegmentButton>
                  <IonSegmentButton className="segment-btn" value="tip">
                    {t("tips")}
                  </IonSegmentButton>
                </IonSegment>
              </IonToolbar>
              {itemType === "howto" ? (
                <IonGrid className="knowledge-items-grid">
                  <IonRow>
                    <HowToItemComponent />
                  </IonRow>
                </IonGrid>
              ) : (
                <IonGrid className="knowledge-items-grid">
                  <IonRow>
                    <TipItemComponent />
                  </IonRow>
                </IonGrid>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
        <FooterDesktop />
      </IonPage>
    </>
  );
};

export default KnowledgeItems;
