import React, { useState, useRef, useEffect } from "react";
import Tooltip from "react-tooltip-lite";

interface Marker {
  text: string;
  lat: any;
  lng: any;
}

const Marker: React.FC<Marker> = ({ text }) => {
  const [open, setOpen] = useState(false);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpen(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  return (
    <Tooltip content={text} useHover={false} isOpen={open}>
      <img
        ref={wrapperRef}
        style={{
          width: "25px",
          height: "35px",
          position: "absolute",
          left: "-25px",
          top: "-35px",
        }}
        src="img/google-map-marker-icon-7.jpg"
        onClick={() => setOpen(true)}
        alt=''
      />
    </Tooltip>
  );
};

export default Marker;
