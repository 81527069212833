import React from "react";
// import { NavLink } from "react-router-dom";
import { IonList, IonItem, IonText } from "@ionic/react";
import { menuController } from "@ionic/core";
import { useTranslation } from "react-i18next";
import ChangeBranchList from "../components/ChangeBranchList";
import { useHistory } from "react-router-dom";

const UnloggedMobileMenu = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const closeMenu = async () => {
    await menuController.close();
  };
  return (
    <IonList className="mobile-menu-list mobile-unlogged-menu-list">
      <IonItem>
        {/* <NavLink to="/change_branch" onClick={closeMenu}>
          {t('change_branch')}
        </NavLink> */}
        {/* <IonText className="position-center">
          <h1>Change Branch</h1>
        </IonText> */}
        <ChangeBranchList

          hideBranchList={() => history.goBack()}
          closeMenu={() => closeMenu()}
        />
      </IonItem>
    </IonList>
  );
};

export default UnloggedMobileMenu;
