import React, { useState, useEffect } from "react";
import {
  IonContent,
  IonList,
  IonItem,
  IonAvatar,
  IonButton,
} from "@ionic/react";
import { useTranslation } from "react-i18next";

import { menuController } from "@ionic/core";

import { NavLink } from "react-router-dom";

const LoggedMobileMenu = () => {
  const [userName, setUserName] = useState("");
  const { t } = useTranslation();
  const closeMenu = async () => {
    await menuController.close();
  };

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("user")!);
    if (user) setUserName(user.name);
  }, []);

  return (
    <>
      <IonContent>
        <div className="mobile-menu-user">
          <IonButton
            className="sign-out-link bold"
            fill="clear"
            onClick={() => {
              closeMenu();
              localStorage.clear();
              window.location.replace("/login");
            }}
          >
            {t("sign_out")}
          </IonButton>
          <div className="content">
            <IonAvatar className="user-photo">
              <img src="img/avatar.svg" alt="User" />
            </IonAvatar>
            <h4 className="user-greeting">Hello, {userName}</h4>
            <NavLink className="user-button" to="/settings" onClick={closeMenu}>
              {t("update_profile")}
            </NavLink>
          </div>
        </div>
        <IonList className="mobile-menu-list mobile-nav-menu-list">
          <IonItem>
            <NavLink to="/home" onClick={closeMenu}>
              <img
                src="./img/mobile_menu/menu-dashboard-icon.png"
                alt="Dashboard"
              />
              {t("dashboard")}
            </NavLink>
          </IonItem>
          <IonItem onClick={closeMenu}>
            <NavLink to="/my_points">
              <img src="./img/mobile_menu/menu-star-icon.png" alt="My Points" />
              {t("my_points")}
            </NavLink>
          </IonItem>
          <IonItem>
            <NavLink to="/redeem" onClick={closeMenu}>
              <img src="./img/mobile_menu/menu-present-icon.png" alt="Redeem" />
              {t("redeem")}
            </NavLink>
          </IonItem>
          <IonItem>
            <NavLink to="/support" onClick={closeMenu}>
              <img
                src="./img/mobile_menu/menu-headphones-icon.png"
                alt="Support"
              />
              {t("support")}
            </NavLink>
          </IonItem>
          <IonItem>
            <NavLink to="/knowledge_center" onClick={closeMenu}>
              <img
                src="./img/mobile_menu/menu-cap-icon.png"
                alt="Knowledge center"
              />
              {t("knowledge_center")}
            </NavLink>
          </IonItem>
          <IonItem>
            <a
              href="https://catalog.visonic.com/"
              target="_blank"
              rel="noopener noreferrer"
              onClick={closeMenu}
            >
              <img
                src="./img/mobile_menu/menu-book-icon.png"
                alt="Online Catalog"
                className="online-catalog-btn"
              />
              Online Catalog
            </a>
          </IonItem>
          <IonItem>
            <NavLink to="/languages" onClick={closeMenu}>
              <img
                src="./img/mobile_menu/menu-earth-icon.png"
                alt="Languages"
              />
              {t("languages")}
            </NavLink>
          </IonItem>
        </IonList>
      </IonContent>
    </>
  );
};

export default LoggedMobileMenu;
