import React, { useState, useEffect } from "react";
import { IonFooter, IonGrid, IonRow, IonCol, IonList } from "@ionic/react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface ContainerProps {}

const FooterDesktop: React.FC<ContainerProps> = () => {
  const { t, i18n } = useTranslation();
  const [isAuth, setIsAuth] = useState(false);
  const currentYear = new Date().getFullYear()

  function isLog() {
    if (localStorage.getItem("token")) {
      setIsAuth(true);
    } else {
      setIsAuth(false);
    }
  }
  useEffect(() => {
    isLog();
  }, [localStorage]);

  return (
    <IonFooter
      className={
        isAuth
          ? "ion-no-border footer desktop-footer logged-footer"
          : "ion-no-border footer desktop-footer unlogged-footer"
      }
      // onLoad={() => isLog()}
    >
      <IonGrid className="container">
        <IonRow>
          <IonCol size="12" size-lg="8" className="footer-menu-col">
            <IonList className="footer-menu-list dsad">
              <NavLink
                replace
                className={!isAuth ? "logged-menu-link" : ""}
                to="/home"
              >
                {t('dashboard')}
              </NavLink>
              <NavLink
              replace
                className={!isAuth ? "logged-menu-link" : ""}
                to="/my_points"
              >
                {t('my_points')}
              </NavLink>
              <NavLink
                replace
                className={!isAuth ? "logged-menu-link" : ""}
                to="/redeem"
              >
                {t('redeem')}
              </NavLink>
              <NavLink
                replace
                className={!isAuth ? "logged-menu-link" : ""}
                to="/knowledge_center"
              >
                {t('knowledge_center')}
              </NavLink>
              <NavLink
                replace
                className={!isAuth ? "logged-menu-link" : ""}
                to="/support"
              >
                {t('support')}
              </NavLink>
              <a
                href="https://www.johnsoncontrols.com/legal/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('privacy_notice')}
                
              </a>
              {/* <a
                href="https://www.johnsoncontrols.com/legal/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('unsubscribe')}
              </a> */}
            </IonList>
          </IonCol>
          <IonCol size="12" size-lg="4">
            <img className="footer-logo" src="img/logo-jonson.png" alt="" />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <p className="copyright position-center">
              &copy;{`${currentYear} Johnson Controls. All rights reserved`}
            </p>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonFooter>
  );
};

export default FooterDesktop;
