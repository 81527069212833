import { IonInput, IonItem, IonList, IonButton } from "@ionic/react";
import React, { useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import api from "../api";
import axios from "axios";
import { loadingController } from "@ionic/core";
import helper from "../helper";
import { BranchContext } from "../context/BranchContext";

interface ContainerProps {}

const LoginComponent: React.FC<ContainerProps> = () => {
  const { t, i18n } = useTranslation();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const { data } = useContext(BranchContext)!;

  const submit = async () => {
    const loading = await loadingController.create({
      message: "Please wait...",
    });
    loading.present();
    const config = {
      params: {
        email: email,
        password: password,
        branch: data.branch,
      },
    };
    axios
      .get(api.login, config)
      .then((res) => {
        loading.dismiss().then(() => {
          if (!res.data.success) {
            helper.showToast("Incorrect username or password", "top");
            return;
          }

          localStorage.setItem("token", res.data.token);
          localStorage.setItem("levelImage", res.data.user.levelImage);
          localStorage.setItem(
            "captcha",
            res.data.staticData.RECAPTCHA_SITE_KEY
          );
          localStorage.setItem(
            "onlineCatalog",
            res.data.staticData.ONLINE_CATALOG_URL
          );
          localStorage.setItem("user", JSON.stringify(res.data.user));

          setLanguage(res.data.user.branch.primaryLanguage);
          window.location.replace("/home?branch=" + res.data.user.branch.name);
        });
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  function setLanguage(language: string) {
    i18n.changeLanguage(language);
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        submit();
      }}
    >
      <IonList lines="full" class="ion-no-margin login-form">
        <IonItem className="field-wrapper">
          <IonInput
            type="email"
            value={email}
            placeholder={t("username")}
            onIonChange={(e) => setEmail(e.detail.value!)}
            required={true}
            className="custom-input custom-field"
          ></IonInput>
        </IonItem>
        <IonItem className="field-wrapper">
          <IonInput
            type="password"
            value={password}
            placeholder={t("password")}
            onIonChange={(e) => setPassword(e.detail.value!)}
            required={true}
            className="custom-input custom-field"
          ></IonInput>
        </IonItem>
      </IonList>
      <section className="position-center">
        <IonButton
          type="submit"
          fill="solid"
          shape="round"
          className="custom-btn"
        >
          {t("login")}
        </IonButton>
      </section>
      <section className="position-center">
        <NavLink to="/forgot_password" className="menulink">
          <IonButton fill="clear" className="secondary-btn">
            {t("forgot_password")}
          </IonButton>
        </NavLink>
      </section>
    </form>
  );
};

export default LoginComponent;
