import React, { useEffect } from "react";
import {
  IonPage,
  IonText,
  IonGrid,
  IonRow,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonCol
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ChangeBranchList from "../components/ChangeBranchList";

const ChangeBranch = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    document.title = t("page_titles.change_branch");
  }, [t]);

  return (
    <>
      <IonPage
        className="main-page-content"
        dir={i18n.language === "IL" ? "rtl" : "ltr"}
      >
        <IonHeader className="back-header">
          <IonToolbar>
            <IonButtons className="back-btn" slot="start">
              <IonBackButton className="back-icon" />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonGrid className="select-country-page">
          <IonGrid className="container">
            <IonRow>
              {/* <IonText className="position-center"></IonText> */}
              <IonCol>
                <ChangeBranchList hideBranchList={() => history.goBack()} />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonGrid>
      </IonPage>
    </>
  );
};

export default ChangeBranch;
