import React, { useState, useEffect, createRef } from "react";

import axios from "axios";

import {
  IonInput,
  IonItem,
  IonLabel,
  IonButton,
  IonDatetime,
  IonCheckbox,
  IonText,
  IonPicker,
} from "@ionic/react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

import api from "../api";
import { Plugins } from "@capacitor/core";
import helper from "../helper";
import { loadingController } from "@ionic/core";
import { useHistory } from "react-router-dom";
import { PickerColumn } from "@ionic/core";
import { useTranslation } from "react-i18next";
import { isEmailValid } from "../validation/email";
import { isAlphabeticlValid } from "../validation/alphabetic";
// import { isAlphabeticAndNumberslValid } from "../validation/alphabeticAndNumbers";

interface ContainerProps {}
// const errorsRef = createRef<HTMLDivElement>();
// const scrollToRefObject  = (ref: any) => window.scrollTo(0, ref.current.offsetTop)
const SignUpComponent: React.FC<ContainerProps> = () => {
  const [accountType, setAccountType] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [company, setCompany] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [selectedDate, setSelectedDate] = useState<string>();
  const [country, setCountry] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [addressLine1, setAddressLine1] = useState<string>("");
  const [addressLine2, setAddressLine2] = useState<string>("");
  const [postalCode, setPostalCode] = useState<string>("");

  const [validationErrors, setValidationErrors] = useState<any>([]);

  // const errorsRef = createRef<HTMLDivElement>(null);
  const fieldRef = React.useRef<HTMLInputElement>(null);
  // const errorsRef = useRef(null)
  // const executeScroll = () => scrollToRef(errorsRef)

  const [
    receiveFurtherCommunications,
    setReceiveFurtherCommunications,
  ] = useState<boolean>(false);
  const [branch, setBranch] = useState<string>("");

  const [openBranch, setOpenBranch] = useState<boolean>(false);
  const [openType, setOpenType] = useState<boolean>(false);

  const { t, i18n } = useTranslation();

  const history = useHistory();

  const { Network } = Plugins;

  const branchColumn = {
    name: "Branch",
    options: [
      { text: "IL", value: "IL" },
      { text: "SP", value: "SP" },
      { text: "PT", value: "PT" },
      { text: "UK", value: "UK" },
      { text: "FR", value: "FR" },
      { text: "NR", value: "NR" },
      { text: "OR", value: "OR" },
      { text: "NL", value: "NL" },
      { text: "SA", value: "SA" },
      { text: "IT", value: "IT" },
    ],
  } as PickerColumn;

  const accountTypeColumn = {
    name: "AccountType",
    options: [
      // { text: t('accountTypeColumn.employee'), value: "Employee" },
      { text: t("accountTypeColumn.installer"), value: "Installer" },
      { text: t("accountTypeColumn.partner"), value: "Partner" },
      // { text: t('accountTypeColumn.insurance_reviewer'), value: "Insurance Reviewer" },
      {
        text: t("accountTypeColumn.installer_group"),
        value: "Installer Group",
      },
    ],
  };

  Network.addListener("networkStatusChange", (status) => {
    console.log("Network status changed", status);
    if (status.connected) {
      let userInfo = localStorage.getItem("userInfo");
      if (userInfo) {
        addUser(JSON.parse(userInfo));
        localStorage.removeItem("userInfo");
      }
    }
  });

  let getStatus = async () => await Network.getStatus();

  const handleSubmitForm = async () => {
    const user = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone,
      dateOfBirth: selectedDate,
      addressLine1: addressLine1,
      addressLine2: addressLine2,
      country: country,
      postalCode: postalCode,
      customerType: accountType,
      city: city,
      county: state,
      company: company,
      branch: branch,
      receiveFurtherCommunications: receiveFurtherCommunications,
    };

    getStatus().then(function (value) {
      if (value.connected) {
        addUser(user);
      } else {
        localStorage.setItem("userInfo", JSON.stringify(user));
      }
    });
  };

  async function addUser(value: object) {
    const loading = await loadingController.create({
      message: "Please wait...",
    });
    loading.present();
    axios.post(api.addUser, value).then((res) => {
      console.log(res);
      console.log(res.data);
      if (res.data.success) {
        window.location.replace("/home");
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("levelImage", res.data.user.levelImage);
        localStorage.setItem("captcha", res.data.staticData.RECAPTCHA_SITE_KEY);
        localStorage.setItem(
          "onlineCatalog",
          res.data.staticData.ONLINE_CATALOG_URL
        );
        localStorage.setItem("user", JSON.stringify(res.data.user));

        setLanguage(res.data.user.branch.primaryLanguage);
      }

      loading.dismiss().then(() => {
        if (!res.data.success)
          helper.showAlert(res.data.success, res.data.errorMessage);
      });
    });
  }

  function setLanguage(language: string) {
    i18n.changeLanguage(language);
  }

  function handleFormValidation(event: any) {
    event.preventDefault();
    const errors = [];
    if (accountType.trim() === "") {
      errors.push(t("validation.account_type_empty"));
    }
    if (firstName.trim() === "") {
      errors.push(t("validation.firstName.firstName_empty"));
    }
    if (!isAlphabeticlValid(firstName.trim())) {
      errors.push(t("validation.firstName.firstName_valid"));
    }
    if (lastName.trim() === "") {
      errors.push(t("validation.lastName.lastName_empty"));
    }
    if (!isAlphabeticlValid(lastName.trim())) {
      errors.push(t("validation.lastName.lastName_valid"));
    }
    if (company.trim() === "") {
      errors.push(t("validation.company_empty"));
    }
    if (phone.trim() === "") {
      errors.push(t("validation.phone.phone_empty"));
    }
    if (email.trim() === "") {
      errors.push(t("validation.email.email_empty"));
    }
    if (!isEmailValid(email.trim())) {
      errors.push(t("validation.email.email_valid"));
    }
    if (country.trim() === "") {
      errors.push(t("validation.country.country_empty"));
    }
    if (!isAlphabeticlValid(country.trim())) {
      errors.push(t("validation.country.country_valid"));
    }
    if (state.trim() === "") {
      errors.push(t("validation.state_empty"));
    }
    if (addressLine1.trim() === "") {
      errors.push(t("validation.address_line_1.address_line_1_empty"));
    }
    if (addressLine1.trim() === "") {
      errors.push(t("validation.address_line_2.address_line_2_empty"));
    }

    if (city.trim() === "") {
      errors.push(t("validation.city.city_empty"));
    }
    if (postalCode.trim() === "") {
      errors.push(t("validation.postal_code_empty"));
    }
    // console.log("errors arr", errors);
    if (errors.length) {
      setValidationErrors([]);
      setValidationErrors(errors);
    } else {
      setValidationErrors([]);
      console.log("send form");
      handleSubmitForm();
    }
  }
  useEffect(() => {
    if (validationErrors.length) {
      fieldRef.current!.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [validationErrors]);

  return (
    <>
      <Accordion
        className="accordion-custom sign-up-accordion"
        preExpanded={["First"]}
      >
        <div ref={fieldRef}>
          {validationErrors.length ? (
            <div className="form-errors-block">
              <ul>
                {validationErrors.map((item: any, index: number) => {
                  return <li key={index}>{item}</li>;
                })}
              </ul>
            </div>
          ) : null}
        </div>
        <form
          className="reg-form"
          onSubmit={(event) => {
            handleFormValidation(event);
          }}
        >
          <AccordionItem uuid="First">
            <AccordionItemHeading>
              <AccordionItemButton>
                <div className="accordion-label">
                  {t("personal_information")}
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>

            <AccordionItemPanel>
              <IonItem className="select-box field-wrapper">
                <IonLabel className="select-box-label">{t("i_am")}</IonLabel>
                <IonButton
                  className="button-select-custom"
                  onClick={() => setOpenType(true)}
                >
                  {accountType}
                </IonButton>
                <IonPicker
                  isOpen={openType}
                  // text="Installer"
                  columns={[accountTypeColumn]}
                  buttons={[
                    {
                      text: t("cancel"),
                      role: "cancel",
                      handler: () => {
                        setOpenType(false);
                      },
                    },
                    {
                      text: t("confirm"),
                      handler: (value) => {
                        setAccountType(value.AccountType.value);
                        setOpenType(false);
                      },
                    },
                  ]}
                ></IonPicker>
              </IonItem>
              <IonItem className="field-wrapper">
                <IonLabel className="select-box-label">
                  {t("select_branch")}
                </IonLabel>
                <IonButton
                  className="button-select-custom"
                  onClick={() => setOpenBranch(true)}
                >
                  {branch}
                </IonButton>
                <IonPicker
                  isOpen={openBranch}
                  columns={[branchColumn]}
                  buttons={[
                    {
                      text: t("cancel"),
                      role: "cancel",
                      handler: () => {
                        setOpenBranch(false);
                      },
                    },
                    {
                      text: t("confirm"),
                      handler: (value) => {
                        setBranch(value.Branch.value);
                        setOpenBranch(false);
                      },
                    },
                  ]}
                ></IonPicker>
              </IonItem>

              <IonItem className="field-wrapper">
                <IonInput
                  type="text"
                  value={firstName}
                  placeholder={t("first_name")}
                  onIonChange={(e) => setFirstName(e.detail.value!)}
                  //required={true}
                  className="custom-input custom-field"
                ></IonInput>
              </IonItem>

              <IonItem className="field-wrapper">
                <IonInput
                  type="text"
                  value={lastName}
                  placeholder={t("last_name")}
                  onIonChange={(e) => setLastName(e.detail.value!)}
                  //required={true}
                  className="custom-input custom-field"
                ></IonInput>
              </IonItem>

              <IonItem className="field-wrapper">
                <IonInput
                  type="text"
                  value={company}
                  placeholder={t("company")}
                  onIonChange={(e) => setCompany(e.detail.value!)}
                  // required={true}
                  className="custom-input custom-field"
                ></IonInput>
              </IonItem>

              <IonItem className="field-wrapper">
                <IonInput
                  type="number"
                  value={phone}
                  placeholder={t("phone")}
                  onIonChange={(e) => setPhone(e.detail.value!)}
                  className="custom-input custom-field"
                ></IonInput>
              </IonItem>

              <IonItem className="field-wrapper">
                <IonInput
                  // type="email"
                  value={email}
                  placeholder={t("email")}
                  onIonChange={(e) => setEmail(e.detail.value!)}
                  // required={true}
                  className="custom-input custom-field"
                ></IonInput>
              </IonItem>

              <IonItem className="field-wrapper date-field-wrapper">
                <IonText>
                  <h4 className="font-bold">{t("date_of_birth")}</h4>
                </IonText>
                <IonDatetime
                  className="button-select-custom button-date-custom"
                  displayFormat="MM/DD/YYYY"
                  doneText="Confirm"
                  min="1900-03-14"
                  //max="2012-12-31"
                  value={selectedDate}
                  onIonChange={(e) => setSelectedDate(e.detail.value!)}
                ></IonDatetime>
              </IonItem>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <div className="accordion-label">
                  {t("contact_information")}
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <IonItem class="field-wrapper">
                <IonText>
                  <h4 className="font-bold">{t("address")}</h4>
                  <p>{t("your_full_adress")}</p>
                </IonText>
              </IonItem>
              <IonItem className="field-wrapper small-margin">
                <IonInput
                  type="text"
                  value={country}
                  placeholder={t("country")}
                  onIonChange={(e) => setCountry(e.detail.value!)}
                  // required={true}
                  className="custom-input custom-field"
                ></IonInput>
              </IonItem>

              <IonItem className="field-wrapper small-margin">
                <IonInput
                  type="text"
                  value={state}
                  placeholder={t("state_province")}
                  onIonChange={(e) => setState(e.detail.value!)}
                  className="custom-input custom-field"
                ></IonInput>
              </IonItem>

              <IonItem className="field-wrapper small-margin">
                <IonInput
                  type="text"
                  value={addressLine1}
                  placeholder={t("address_l_1")}
                  onIonChange={(e) => setAddressLine1(e.detail.value!)}
                  // required={true}
                  className="custom-input custom-field"
                ></IonInput>
              </IonItem>

              <IonItem className="field-wrapper small-margin">
                <IonInput
                  type="text"
                  value={addressLine2}
                  placeholder={t("address_l_2")}
                  onIonChange={(e) => setAddressLine2(e.detail.value!)}
                  className="custom-input custom-field"
                ></IonInput>
              </IonItem>

              <IonItem className="field-wrapper small-margin">
                <IonInput
                  type="text"
                  value={city}
                  placeholder={t("city")}
                  onIonChange={(e) => setCity(e.detail.value!)}
                  // required={true}
                  className="custom-input custom-field"
                ></IonInput>
              </IonItem>

              <IonItem className="field-wrapper small-margin">
                <IonInput
                  type="text"
                  value={postalCode}
                  placeholder={t("postal_code")}
                  onIonChange={(e) => setPostalCode(e.detail.value!)}
                  // required={true}
                  className="custom-input custom-field"
                ></IonInput>
              </IonItem>
              <div className="field-wrapper">
                <IonCheckbox
                  checked={receiveFurtherCommunications}
                  onIonChange={(e) =>
                    setReceiveFurtherCommunications(e.detail.checked)
                  }
                  className="custom-checkbox"
                />
                <IonLabel>{t("yes_i_wish")}</IonLabel>
                <hr className="form-line" />
                <IonLabel>
                  {i18n.language !== "IL" ? (
                    <>
                      {t("by_clicking")}
                      <a
                        className="by-clicking-link"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.johnsoncontrols.com/legal/privacy"
                      >
                        <br />
                        {t("global_privacy")}
                      </a>
                    </>
                  ) : (
                    <>
                      <span>קראתי את</span>
                      &nbsp;
                      <a
                        className="by-clicking-link"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.johnsoncontrols.com/legal/privacy"
                      >
                        {t("global_privacy")}
                      </a>
                      &nbsp;
                      <span>ואני מאשר אותם</span>
                    </>
                  )}
                </IonLabel>
              </div>
            </AccordionItemPanel>
          </AccordionItem>
          <IonButton
            class="custom-btn register-button"
            type="submit"
            fill="solid"
            shape="round"
          >
            {t("register")}
          </IonButton>
        </form>
      </Accordion>
    </>
  );
};

export default SignUpComponent;
