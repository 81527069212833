import React from "react";
import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonBackButton,
  IonButtons,
  IonThumbnail
} from "@ionic/react";
import FooterDesktop from "../components/FooterDesktop";

interface ContainerProps { }

const NotFoundPage: React.FC<ContainerProps> = () => {
  return (
    <>
    <IonPage>
      <IonHeader className="back-header">
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="main-container main-page-content">
        <section className="error-section">
          <IonThumbnail className="error-img-wrapper">
            <img src="./img/error_pic.svg" alt="" />
          </IonThumbnail>
          <p className="bold">Page was not found</p>
        </section>
      </IonContent>
    <FooterDesktop/>
    </IonPage>
    </>
  );
};

export default NotFoundPage;
