import {
  IonCard,
  IonCardContent,
  IonSpinner,
  IonCol,
  IonCardSubtitle,
  IonCardTitle,
  IonButton,
  IonModal,
} from "@ionic/react";
import React, { useState, useEffect, useRef, useCallback } from "react";
import api from "../api";
import axios from "axios";

interface ContainerProps {}

const HowToItemComponent: React.FC<ContainerProps> = () => {
  interface Item {
    id: string;
    description: string;
    date: Date;
    contentUrl: string;
    imageUrl: string;
  }
  const [items, setItems] = useState(Array<Item>());

  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [contentUrl, setContentUrl] = useState("");
  const [showModal, setShowModal] = useState(false);

  const observer: any = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setCurrentPage((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  function getConfig(limit: number, page: number, token: string | null) {
    return {
      params: {
        limit: limit,
        page: page,
      },
      headers: {
        Authorization: "Bearer " + token,
      },
    };
  }
  function openModal(item: any) {
    setContentUrl(item.contentUrl);

    setShowModal(true);
  }
  useEffect(() => {
    setLoading(true);
    axios
      .get(
        api.howToKnowledgeItems,
        getConfig(10, currentPage, localStorage.getItem("token"))
      )
      .then((res) => {
        console.log(res.data);
        setLoading(false);
        setHasMore(res.data.results.length > 0);
        setItems(items.concat(res.data.results));
      });
  }, [currentPage]);

  return (
    <>
      {items.map((item, index) => {
        if (items.length === index + 1) {
          return (
            <IonCol
              className="knowledge-item"
              size="12"
              size-sm="6"
              size-md="4"
              key={item.id}
              onClick={() => {
                openModal(item);
              }}
            >
              <IonCard className="knowledge-card" ref={lastBookElementRef}>
                <div className="card-media">
                  <img
                    src={item.imageUrl}
                    onClick={() => {
                      openModal(item);
                    }}
                  />
                  <div className="how-to-card-hover card-hover">
                    <img
                      className="card-hover-label"
                      src="img/play-bttn-ico.png"
                      alt=""
                    />
                  </div>
                </div>
                <IonCardContent>
                  <IonCardTitle className="knowledge-card-title">
                    {item.description}
                  </IonCardTitle>
                  <IonCardSubtitle className="knowledge-card-subtitle">
                    {item.date}
                  </IonCardSubtitle>
                </IonCardContent>
              </IonCard>
            </IonCol>
          );
        } else
          return (
            <IonCol
              className="knowledge-item"
              size="12"
              size-sm="6"
              size-md="4"
              key={item.id}
              onClick={() => {
                openModal(item);
              }}
            >
              <IonCard className="knowledge-card">
                <div className="card-media">
                  <img
                    src={item.imageUrl}
                    onClick={() => {
                      openModal(item);
                    }}
                  />
                  <div className="how-to-card-hover card-hover">
                    <img
                      className="card-hover-label"
                      src="img/play-bttn-ico.png"
                      alt=""
                    />
                  </div>
                </div>

                <IonCardContent>
                  <IonCardTitle className="knowledge-card-title">
                    {item.description}
                  </IonCardTitle>
                  <IonCardSubtitle className="knowledge-card-subtitle">
                    {item.date}
                  </IonCardSubtitle>
                </IonCardContent>
              </IonCard>
            </IonCol>
          );
      })}
      <IonModal
        cssClass="how-to-modal"
        isOpen={showModal}
        backdropDismiss
        onDidDismiss={() => setShowModal(false)}
      >
        <iframe
          width="560"
          height="315"
          src={contentUrl + "?controls=1&modestbranding=1&fs=1"}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <IonButton
          className="close-modal"
          onClick={() => setShowModal(false)}
        ></IonButton>
      </IonModal>
      {loading && <IonSpinner name="crescent" />}
    </>
  );
};

export default HowToItemComponent;
