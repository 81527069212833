import React from "react";
import {
  IonPage,
  IonText,
  IonHeader,
  IonIcon,
  IonButton,
  IonContent,
} from "@ionic/react";
import { useHistory } from "react-router-dom";
import { chevronBack } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import ChangeLangList from "../components/ChangeLangList";

const Languages = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  return (
    <IonPage dir={i18n.language === "IL" ? "rtl" : "ltr"}>
      <IonHeader className="back-header">
        <IonButton
          fill="clear"
          className="back-button"
          onClick={() => history.goBack()}
        >
          <IonIcon icon={chevronBack}></IonIcon>
          Back
        </IonButton>
      </IonHeader>
      <IonContent>
        <div className="select-country-page select-country-block">
          <IonText className="position-center">
            <h1>{t("languages")}</h1>
          </IonText>
          <ChangeLangList />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Languages;
