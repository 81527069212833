import React from "react";
import { IonFooter, IonButton, IonIcon, isPlatform } from "@ionic/react";

import { NavLink } from "react-router-dom";
import {
  homeSharp,
  giftSharp,
  headsetSharp,
  barcode,
  scan,
} from "ionicons/icons";

interface ContainerProps {}

const FooterMobile: React.FC<ContainerProps> = () => {
  // const [isAuth, setIsAuth] = useState(false);

  // function isLog() {
  //   if (localStorage.getItem("token")) {
  //     setIsAuth(true);
  //   } else {
  //     setIsAuth(false);
  //   }
  // }
  // useEffect(() => {
  //   isLog();
  // }, [localStorage]);

  return (
    <IonFooter className="footer mobile-footer">
      <IonButton fill="clear" className="footer-btn">
        <NavLink className="footer-link" to="/home">
          <IonIcon icon={homeSharp} />
        </NavLink>
      </IonButton>
      <IonButton fill="clear" className="footer-btn">
        <NavLink className="footer-link" to="/my_points">
          <IonIcon icon={barcode} />
        </NavLink>
      </IonButton>
      <IonButton
        fill="clear"
        className={
          isPlatform("mobileweb") ? "footer-btn hidden-button" : "footer-btn"
        }
      >
        <NavLink to="/scanner" className="footer-link">
          <IonIcon icon={scan} />
        </NavLink>
      </IonButton>
      <IonButton fill="clear" className="footer-btn">
        <NavLink className="footer-link" to="/redeem">
          <IonIcon icon={giftSharp} />
        </NavLink>
      </IonButton>
      <IonButton fill="clear" className="footer-btn">
        <NavLink className="footer-link" to="/support">
          <IonIcon icon={headsetSharp} />
        </NavLink>
      </IonButton>
    </IonFooter>
  );
};

export default FooterMobile;
