import { Plugins } from "@capacitor/core";
import { toastController } from "@ionic/core";

const { Modals, Storage } = Plugins;

async function showToast(message: string, position: any) {
  const toast = await toastController.create({
    color: "dark",
    duration: 2000,
    message: message,
    position: position,
  });

  await toast.present();
}

async function setObject(key: string, value: string) {
  return await Storage.set({
    key: key,
    value: value,
  });
}

async function showAlert(success: boolean, message: string) {
  await Modals.alert({
    title: success ? "Success" : "Failed",
    message: success ? "Success" : message,
  });
}

async function showAlertSupport(success: boolean, message: string, messageBody: string) {
  await Modals.alert({
    title: success ? "Success" : "Failed",
    message: success ? messageBody : message,
  });
}

export default {
  setObject,
  showAlert,
  showToast,
  showAlertSupport
};
