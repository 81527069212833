import React, { useEffect } from "react";
import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonBackButton,
  IonButtons,
  IonThumbnail,
  IonButton
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import FooterDesktop from "../components/FooterDesktop";

interface ContainerProps { }

const NotFoundPage: React.FC<ContainerProps> = () => {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    document.title = t("page_titles.no_connection")
  }, [t])
  return (
    <>
    <IonPage dir={i18n.language === "IL" ? "rtl" : "ltr"}>
      <IonHeader className="back-header">
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="main-container main-page-content">
        <section className="error-section">
          <IonThumbnail className="error-img-wrapper">
            <img src="./img/error_pic.svg" alt="" />
          </IonThumbnail>
          <div className="error-text">
            <p className="bold">{t("no_internet")}</p>
            <p>{t("check_connection")}</p>
          </div>
          <IonButton
            className="custom-btn"
            fill="clear"
          >
            {t("try_again")}
          </IonButton>
        </section>
      </IonContent>
    <FooterDesktop/>
    </IonPage>
    </>
  );
};

export default NotFoundPage;
