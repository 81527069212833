import {
  IonInput,
  IonButton,
  IonPage,
  IonItem,
  
  IonGrid,
  IonRow,
  IonCol,
  
} from "@ionic/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import api from "../api";
import axios from "axios";
import helper from "../helper";
import BackToolbar from "../components/BackToolbar";
import { useHistory } from "react-router-dom";


interface ContainerProps {}

const ForgotPassword: React.FC<ContainerProps> = () => {
  const { t, i18n } = useTranslation();
  
  const [email, setEmail] = useState("");
  const history = useHistory();

  function getConfig() {
    return {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
  }

  const submit = async () => {
    let bodyFormData = new FormData();
    bodyFormData.set("email", email);
    try {
      axios.post(api.forgotPassword, bodyFormData, getConfig()).then((res) => {
        console.log(res);
        helper.showAlert(res.data.success, res.data.errorMessage);
        history.push("/login");
      });
    } catch (e) {}
  };

  return (
    <IonPage dir={i18n.language === "IL" ? "rtl" : "ltr"}>
      <BackToolbar />
      <IonGrid className="container">
        <IonGrid className="forgot-pass-content">
          <IonRow>
            <IonCol>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  submit();
                }}
              >
                <IonItem className="field-wrapper">
                  <IonInput
                    type="email"
                    value={email}
                    placeholder={t("email")}
                    onIonChange={(e) => setEmail(e.detail.value!)}
                    className="custom-input custom-field"
                  ></IonInput>
                </IonItem>
                <IonButton
                  fill="solid"
                  shape="round"
                  type="submit"
                  className="custom-btn"
                >
                  {t("send_password")}
                </IonButton>
              </form>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonGrid>
    </IonPage>
  );
};

export default ForgotPassword;
