import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { BarcodeScanner } from "@ionic-native/barcode-scanner";

const Scanner: React.FC = () => {
  const history = useHistory();

  useEffect(() => {
    const openScanner = async () => {
      console.log("opened");

      const data = await BarcodeScanner.scan({
        showTorchButton: true,
        resultDisplayDuration: 500,
      });

      if (data.cancelled.toString() === "1") {
        console.log("pushed");

        history.push("/verify_barcode");
      } else {
        history.push("/verify_barcode", {
          barcode: data.text,
        });
      }

      console.log(`Barcode data: ${data.text}`);
    };
    openScanner();
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle></IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent></IonContent>
    </IonPage>
  );
};
export default Scanner;
