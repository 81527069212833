import React, { useEffect, useState } from "react";
import {
  IonPage,
  IonText,
  IonPicker,
  IonIcon,
  IonButton,
  IonTitle,
  IonToolbar,
  IonHeader,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { PickerColumn } from "@ionic/core";
import { useTranslation } from "react-i18next";
import { chevronForward, chevronBack } from "ionicons/icons";
import FooterDesktop from "../components/FooterDesktop";

const Settings: React.FC = () => {
  const [username, setUsername] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState("");
  const history = useHistory();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  const LanguageColumn = {
    name: "Language",
    options: [
      { text: "English", value: "UK" },
      { text: "Hebrew", value: "IL" },
      { text: "French", value: "FR" },
      { text: "Romanian", value: "RO" },
    ],
  } as PickerColumn;

  useEffect(() => {
    let user = localStorage.getItem("user");
    if (user) {
      setUsername(JSON.parse(user).name);
    }
    setLanguage(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    document.title = t("page_titles.settings");
  }, [t]);

  return (
    <IonPage
      className="main-page-content"
      dir={i18n.language === "IL" ? "rtl" : "ltr"}
    >
      <IonHeader>
        <IonToolbar>
          <IonButton
            fill="clear"
            className="back-button"
            onClick={() => history.goBack()}
          >
            <IonIcon icon={chevronBack}></IonIcon>
            {t('back')}
          </IonButton>
        </IonToolbar>
      </IonHeader>
      <IonGrid className="container">
        <IonRow>
          <IonCol>
            <IonToolbar className="title-toolbar">
              <IonTitle className="page-title">
                {t("page_titles.settings")}
              </IonTitle>
            </IonToolbar>
            <div className="greeting">
              <IonText>
                {t('hi')}, <span className="bold">{username}</span>
              </IonText>
            </div>
            <div className="settings-rows-box">
              <div className="settings-row">
                <IonText className="bold" onClick={() => setIsOpen(true)}>
                  {t('change_language')}
                </IonText>
                <IonButton
                  className="lang-link"
                  onClick={() => setIsOpen(true)}
                >
                  {LanguageColumn.options.map((value) => {
                    if (value.value === language)
                      return <p key={value.text}>{value.text}</p>;
                    return "";
                  })}
                </IonButton>
              </div>
              <div className="settings-row">
                <IonPicker
                  isOpen={isOpen}
                  columns={[LanguageColumn]}
                  buttons={[
                    {
                      text: "Cancel",
                      role: "cancel",
                      handler: () => {
                        setIsOpen(false);
                      },
                    },
                    {
                      text: "Confirm",
                      handler: (value) => {
                        changeLanguage(value.Language.value);
                        setIsOpen(false);
                      },
                    },
                  ]}
                ></IonPicker>
              </div>

              <div className="settings-row">
                <NavLink to="/update_Profile" className="menulink">
                  <IonText className="bold">{t("update_information")}</IonText>
                  <IonButton fill="clear">
                    <IonIcon slot="start" icon={chevronForward} />
                  </IonButton>
                </NavLink>
              </div>
              <div className="settings-row">
                <NavLink to="/change_password" className="menulink">
                  <IonText className="bold">{t("change_password")}</IonText>
                  <IonButton fill="clear">
                    <IonIcon slot="start" icon={chevronForward} />
                  </IonButton>
                </NavLink>
              </div>
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
      <FooterDesktop />
    </IonPage>
  );
};

export default Settings;
