export function isEmailValid(email: string) {
  let valid;
  if (email != null || email !== "") {
    valid = email
      .toLowerCase()
      .match(
        "^[-a-z0-9~!$%^&*_=+}{'?]+(.[-a-z0-9~!$%^&*_=+}{'?]+)*@([a-z0-9_]" +
          "[-a-z0-9_]*(.[-a-z0-9_]+)*.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|" +
          "[a-z][a-z])|([0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}))(:[0-9]{1,5})?$"
      );
  }
  return valid === null ? false : valid;
}
