import React, { useContext } from "react";
import { IonText, IonList, IonItem, IonButton, IonGrid } from "@ionic/react";
import { BranchContext } from "../context/BranchContext";
import { useTranslation } from "react-i18next";

interface ChangeBranchList {
  hideBranchList: any;
  closeMenu?: any;
}

const ChangeBranchList: React.FC<ChangeBranchList> = ({
  hideBranchList,
  closeMenu,
}) => {
  const { i18n } = useTranslation();
  const { data, dispatch } = useContext(BranchContext)!;
  const UPDATE_BRANCH = "UPDATE_BRANCH";

  const changeBranch = (branch: any) => {
    dispatch({
      type: UPDATE_BRANCH,
      branch: branch,
    });
    hideBranchList();
    localStorage.setItem("branch", branch);
    i18n.changeLanguage(branch);
  };

  if (closeMenu) {
    return (
      <IonGrid className="select-country-block">
        <IonText className="position-center">
          <h1>Select your country</h1>
        </IonText>
        <IonList className="mobile-menu-list mobile-country-menu-list">
          <IonItem>
            <IonButton
              onClick={() => {
                changeBranch("UK");
                closeMenu();
              }}
            >
              UK and Ireland <img src="./img/countries/uk.png" alt="flag" />
            </IonButton>
          </IonItem>

          <IonItem>
            <IonButton
              onClick={() => {
                changeBranch("FR");
                closeMenu();
              }}
            >
              France <img src="./img/countries/fr.png" alt="flag" />
            </IonButton>
          </IonItem>
          <IonItem>
            <IonButton
              onClick={() => {
                changeBranch("SP");
                closeMenu();
              }}
            >
              Spain <img src="./img/countries/sp.png" alt="flag" />
            </IonButton>
          </IonItem>

          <IonItem>
            <IonButton
              onClick={() => {
                changeBranch("PT");
                closeMenu();
              }}
            >
              Portugal <img src="./img/countries/pt.svg" alt="flag" />
            </IonButton>
          </IonItem>
          <IonItem>
            <IonButton
              onClick={() => {
                changeBranch("IL");
                closeMenu();
              }}
            >
              Israel <img src="./img/countries/il.png" alt="flag" />
            </IonButton>
          </IonItem>
        </IonList>
      </IonGrid>
    );
  }

  return (
    <IonGrid className="select-country-block">
      <IonText className="position-center">
        <h1>Select your country</h1>
      </IonText>
      <IonList className="mobile-menu-list mobile-country-menu-list">
        <IonItem>
          <IonButton onClick={() => changeBranch("UK")}>
            UK and Ireland <img src="./img/countries/uk.png" alt="flag" />
          </IonButton>
        </IonItem>

        <IonItem>
          <IonButton onClick={() => changeBranch("FR")}>
            France <img src="./img/countries/fr.png" alt="flag" />
          </IonButton>
        </IonItem>
        <IonItem>
          <IonButton onClick={() => changeBranch("SP")}>
            Spain <img src="./img/countries/sp.png" alt="flag" />
          </IonButton>
        </IonItem>
        <IonItem>
          <IonButton onClick={() => changeBranch("IL")}>
            Israel <img src="./img/countries/il.png" alt="flag" />
          </IonButton>
        </IonItem>
        <IonItem>
          <IonButton
            onClick={() => {
              changeBranch("PT");
            }}
          >
            Portugal <img src="./img/countries/pt.svg" alt="flag" />
          </IonButton>
        </IonItem>
      </IonList>
    </IonGrid>
  );
};

export default ChangeBranchList;
