import React, { useEffect, useState } from "react";
import axios from "axios";
import api from "../api";
import { isPlatform } from "@ionic/react";
import { HashLink as Link } from "react-router-hash-link";
import {
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonPopover,
} from "@ionic/react";
import { NavLink, useHistory } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

// import helper from "../helper";
import FooterDesktop from "../components/FooterDesktop";
import ChangeBranchList from "../components/ChangeBranchList";

const HomeUnlogged = () => {
  const [, setInstallers] = useState([]);
  const { t, i18n } = useTranslation();
  const [showBranchPopover, setShowBranchPopover] = useState(true);

  const history = useHistory();
  function getConfig() {
    return {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
  }


  useEffect(() => {
    let installers = JSON.parse(localStorage.getItem("installers")!);
    if (installers && installers.length > 0) {
      setInstallers(installers);
    } else
      axios.get(api.getInstallers, getConfig()).then((res) => {
        console.log(res);
        if (res.data.success && res.data.installers) {
          localStorage.setItem(
            "installers",
            JSON.stringify(res.data.installers)
          );
        }
      });
  }, []);

  const MobileImagePromo = () => {
    const SwitchDesktopImg = () => {
      switch (i18n.language) {
        case "EN":
          return (
            <img src="img/benefits_picture/VC_updated_Homepage_EN.jpg" alt="" />
          );
        case "FR":
          return (
            <img
              src="img/benefits_picture/VisoniClub_one_pager_FR.jpg"
              alt=""
            />
          );
        case "IL":
          return (
            <img
              src="img/benefits_picture/VisoniClub_one_pager_HE.jpg"
              alt=""
            />
          );
        case "SP":
          return (
            <img
              src="img/benefits_picture/VisoniClub_one_pager_SP.jpg"
              alt=""
            />
          );
        default:
          return (
            <img src="img/benefits_picture/VC_updated_Homepage_EN.jpg" alt="" />
          );
      }
    };
    const SwitchMobileImg = () => {
      switch (i18n.language) {
        case "EN":
          return (
            <img
              src="img/benefits_picture/mobile/VC-collage-mobile-view_UK.jpg"
              alt=""
            />
          );
        case "FR":
          return (
            <img
              src="img/benefits_picture/mobile/VC-collage-mobile-view_fr.png"
              alt=""
            />
          );
        case "IL":
          return (
            <img
              src="img/benefits_picture/mobile/VC-collage-mobile-view_he.png"
              alt=""
            />
          );
        case "SP":
          return (
            <img
              src="img/benefits_picture/mobile/VC-collage-mobile-view_es.jpg"
              alt=""
            />
          );
        default:
          return (
            <img
              src="img/benefits_picture/mobile/VC-collage-mobile-view_UK.jpg"
              alt=""
            />
          );
      }
    };
    if (
      !isPlatform("mobile") ||
      !isPlatform("iphone") ||
      !isPlatform("android")
    ) {
      return (
        <IonRow>
          <IonCol className="promo">
            <SwitchDesktopImg />
          </IonCol>
        </IonRow>
      );
    } else {
      return (
        <IonRow>
          <IonCol className="promo">
            <SwitchMobileImg />
          </IonCol>
        </IonRow>
      );
    }
  };

  return (
    <>
      <IonPage
        className="unlogged-page"
        dir={i18n.language === "IL" ? "rtl" : "ltr"}
      >
        <IonPopover
          isOpen={showBranchPopover}
          cssClass="branch-popover"
          onDidDismiss={(e) => setShowBranchPopover(false)}
        >
          <ChangeBranchList
            hideBranchList={() => setShowBranchPopover(false)}
          />
        </IonPopover>
        <section className="unlogged-banner" id="unloggedBanner">
          <IonGrid className="container unlogged-banner-grid">
            <IonRow>
              <IonCol className="welcome-msg" size-lg="6" size="12">
                <h1>
                  {t("join_visonic_club")} <br />
                  {t("to_earn_valuable_points")}
                </h1>
                <NavLink
                  replace
                  to={{
                    pathname: "/login",
                    state: {
                      signUp: true,
                    },
                  }}
                  className="join-button"
                >
                  {t("join_now")} {">"}
                </NavLink>
                <hr />
                <div className="store-block">
                  <a
                    href="https://apps.apple.com/ru/app/visoni%D1%81-club/id1538153450"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="img/store-ios.png" alt="" />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=io.ionic.visoniclub"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="img/store-google.png" alt="" />
                  </a>
                </div>
              </IonCol>
              <IonCol className="ion-hide-lg-down ion-no-padding banner-phone-block">
                <img src="img/arm_phone.png" alt="" />
              </IonCol>
            </IonRow>
          </IonGrid>
        </section>
        <section className="navigation-section">
          <IonGrid className="container">
            <IonRow className="navigation-list navigation-list_desktop">
              <IonCol size="">
                <Link className="navigation-link" smooth to="#aboutUs">
                  {t("about_us")}
                </Link>
              </IonCol>

              <IonCol size="">
                <Link className="navigation-link" smooth to="#howWorks">
                  {t("how_it_works")}
                </Link>
              </IonCol>
              <IonCol size="">
                <Link className="navigation-link" smooth to="#benefits">
                  {t("benefit_plans")}
                </Link>
              </IonCol>
              <IonCol size="">
                <Link className="navigation-link" smooth to="#map">
                  {t("recommended_installers")}
                </Link>
              </IonCol>
              <IonCol size="">
                <Link className="navigation-link" smooth to="#bottomNavigation">
                  {t("q&a")}
                </Link>
                <NavLink replace to="/login" className="login-button">
                  {t("login")}{" "}
                  <img
                    className="login_arrow"
                    src="./../img/arrow_image.png"
                    alt=""
                  />
                </NavLink>
              </IonCol>
            </IonRow>
            <IonRow className="navigation-list navigation-list_mobile">
              <IonCol size="">
                <NavLink replace to="/login" className="login-button">
                  {t("login")}{" "}
                  <img
                    className="login_arrow"
                    src="./../img/arrow_image.png"
                    alt=""
                  />
                </NavLink>
              </IonCol>
            </IonRow>
          </IonGrid>
        </section>

        <section id="aboutUs" className="about-us-section unlogged-section">
          <IonGrid className="container">
            <IonRow className="dc center-row">
              <IonCol className="ion-text-center" size-md="8">
                <h2 className="unlogged-section-title">{t("about_us")}</h2>
                <p>{t("how_we_invest")}</p>
              </IonCol>
            </IonRow>
          </IonGrid>
        </section>
        <section id="howWorks" className="how-works unlogged-section">
          <IonGrid className="container ion-text-center">
            <IonRow className="dc center-row">
              <IonCol className="ion-text-center" size-md="8">
                <h2 className="unlogged-section-title">
                  {t("how_does_it_works")}
                </h2>
                <p className="font-bold">{t("get_point")}</p>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol className="item" size="12" size-md="6">
                <img src="img/how-lamp.png" alt="" />
                <p>{t("more_you_sell")}</p>
              </IonCol>
              <IonCol className="item" size="12" size-md="6">
                <img src="img/how-phone.png" alt="" />
                <p>{t("you_can-manage")}</p>
              </IonCol>
            </IonRow>
            <IonRow className="center-row dc">
              <IonCol className="ion-text-center" size-md="8">
                <Link to="#unloggedBanner" smooth className="to-top-link">
                  {t("to_top")}&nbsp;{"^"}
                </Link>
              </IonCol>
            </IonRow>
          </IonGrid>
        </section>
        <section className="how-join unlogged-section">
          <IonGrid className="container ion-text-center">
            <h2 className="unlogged-section-title">{t("how_to_join")}</h2>
            <IonRow>
              <IonCol size-md="3" size-sm="6" size="12">
                <img src="img/how-sub.png" alt="" />
                <h3>{t("subscribe.title")}</h3>
                <img className="item-arrow" src="img/arrow-right.png" alt="" />
                <p>
                  <Trans i18nKey="subscribe.description">
                    {/* {" "}
                    Subscribe to Visonic Club via the website:{" "}
                    <a
                      href="https://visoniclub.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      www.visoniclub.com
                    </a>{" "} */}
                    {t("subscribe.description")}
                  </Trans>
                </p>
              </IonCol>
              <IonCol size-md="3" size-sm="6" size="12">
                <img src="img/how-form.png" alt="" />
                <h3>{t("fill_form.title")}</h3>
                <img className="item-arrow" src="img/arrow-right.png" alt="" />
                <p>{t("fill_form.description")}</p>
              </IonCol>
              <IonCol size-md="3" size-sm="6" size="12">
                <img src="img/how-lock.png" alt="" />
                <h3>{t("get_your_password.title")}</h3>
                <img className="item-arrow" src="img/arrow-right.png" alt="" />
                <p>{t("get_your_password.description")}</p>
              </IonCol>
              <IonCol size-md="3" size-sm="6" size="12">
                <img src="img/how-download.png" alt="" />
                <h3>{t("download_app.title")}</h3>
                <img className="item-arrow" src="img/arrow-right.png" alt="" />
                <p>{t("download_app.description")}</p>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol class="ready-strip">
                <p>{t("now_you_ready")}</p>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonButton
                  className="join-button"
                  onClick={() => history.push("login", { signUp: true })}
                >
                  {t("join_now")} {">"}
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </section>
        <section id="benefits" className="benefits-section unlogged-section">
          <IonGrid className="container">
            <IonRow>
              <IonCol size-lg="6" size="12">
                <h2 className="unlogged-section-title" id="test21">
                  {t("benefits_and_plans")}
                </h2>
                <p>{t("learn_about")}</p>
                <p className="ion-margin-top font-bold">
                  {t("the_points_you_have_accumulated")}
                </p>
              </IonCol>
              <IonCol size-lg="6" size="12">
                <IonRow>
                  <IonCol className="cards-img ion-text-center">
                    <img src="img/cards.png" alt="" />
                  </IonCol>
                </IonRow>
                <IonRow className="club-cards">
                  <IonCol>
                    <h4>{t("plans_cards.silver")}</h4>
                  </IonCol>
                  <IonCol>
                    <h4>{t("plans_cards.gold")}</h4>
                  </IonCol>
                  <IonCol>
                    <h4>{t("plans_cards.platinum")}</h4>
                  </IonCol>
                  <IonCol>
                    <h4>{t("plans_cards.diamonds")}</h4>
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>
            <MobileImagePromo />
            <IonRow className="center-row dc">
              <IonCol className="ion-text-center" size-md="8">
                <Link to="#unloggedBanner" smooth className="to-top-link">
                  {t("to_top")}&nbsp;{"^"}
                </Link>
              </IonCol>
            </IonRow>
          </IonGrid>
        </section>
        <section id="map" className="map-section unlogged-section">
          <IonGrid className="container">
            <IonRow>
              <IonCol className="map-details" size="12" size-md="6">
                <h3>
                  {t("recomended")} <br /> {t("installers")}
                </h3>
                <p>{t("great_way")}</p>
                <NavLink
                  to="/recommended_installers"
                  style={{ color: "#ffffff" }}
                >
                  visonic-club-gold-installers
                </NavLink>
              </IonCol>
              <IonCol className="ion-no-padding" size="12" size-md="6">
                <img src="img/map.png" alt="" />
              </IonCol>
            </IonRow>
            <IonRow
              className={
                i18n.language === "IL"
                  ? "bottom-navigation bottom-navigation-right"
                  : "bottom-navigation bottom-navigation-left"
              }
              id="bottomNavigation"
            >
              <IonCol className="bottom-navigation-col">
                <ul>
                  <li>
                    <a
                      href="https://www.visonic.com/questions-and-answers"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t("q&a")}
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.visonic.com/articles-of-visonic-club"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t("terms_conditions")}
                    </a>
                  </li>
                </ul>
              </IonCol>
            </IonRow>
          </IonGrid>
        </section>
        <FooterDesktop />
      </IonPage>
    </>
  );
};

export default HomeUnlogged;
