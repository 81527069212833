import React from "react";
import axios from "axios";

import { IonButton, IonText, IonModal } from "@ionic/react";
import api from "../api";
import { loadingController } from "@ionic/core";
import helper from "../helper";
import { useTranslation } from "react-i18next";

interface Gift {
  name: string;
  points: number;
}

interface MyModal {
  item: Gift;
  showModal: boolean;
  hideModal: any;
  recalculatePoints: any;
}

const MyModal: React.FC<MyModal> = ({
  item,
  showModal,
  hideModal,
  recalculatePoints,
}) => {
  function getGift() {
    return {
      giftProduct: {
        name: item.name,
        points: item.points,
      },
    };
  }

  function getConfig() {
    return {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
  }

  const { t } = useTranslation();
  const addGift = async () => {
    const loading = await loadingController.create({
      message: "Please wait...",
    });
    loading.present();
    axios
      .post(api.addGift, getGift(), getConfig())
      .then((res) => {
        loading.dismiss().then(() => {
          console.log(res);
          localStorage.setItem("user", JSON.stringify(res.data.user));
          recalculatePoints(res.data.user.scoreBalance);
          hideModal();
          if (res.data.success)
            helper.showToast(t('gift_was_added'), "top");
          else helper.showToast(t('went_wrong'), "top");
        });
      })
      .catch(function (error) {
        helper.showToast(error.message, "top");
      });
  };

  return (
    <IonModal isOpen={showModal} cssClass="redeem-modal">
      <IonText>
        <div className="modal-text">
          <p>
            {t("are_you_sure")}
            <span className="font-bold"> {item.name}?</span>
          </p>
          <p className="coast-text">
            {t("it_will_cost")}

            <span className="number-of-points">
              {" "}
              {item.points} {t("points")}.
            </span>
          </p>
        </div>
      </IonText>
      <div>
        <IonButton
          className="close-modal"
          onClick={() => hideModal()}
        ></IonButton>
        <IonButton
          className="yes-btn custom-btn small-btn"
          onClick={() => addGift()}
        >
          {t("yes")}
        </IonButton>
      </div>
    </IonModal>
  );
};

export default MyModal;
