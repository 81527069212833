import {
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonToolbar,
  IonTitle,
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import PersonalMessage from "../components/PersonalMessage";
import FooterDesktop from "../components/FooterDesktop";

const MyPoints: React.FC = () => {
  interface Installation {
    id: string;
    productName: string;
    productPoints: number;
    date: Date;
    productNameDescription: string;
  }
  const [installations, setInstallations] = useState(Array<Installation>());
  const { t, i18n } = useTranslation();

  useEffect(() => {
    let user = localStorage.getItem("user");
    setInstallations(JSON.parse(user!).installations);
  }, []);

  useEffect(() => {
    document.title = t("page_titles.my_points");
  }, [t]);

  return (
    <>
      <IonPage
        className="main-page-content"
        dir={i18n.language === "IL" ? "rtl" : "ltr"}
      >
        <IonGrid className="points-table container">
          <PersonalMessage />
          <IonToolbar className="title-toolbar">
            <IonTitle className="page-title">{t("my_points")}</IonTitle>
          </IonToolbar>

          <IonRow className="header-table-row points-table-row">
            <IonCol size="6">{t("product")}</IonCol>
            <IonCol size="4">{t("date")}</IonCol>
            <IonCol size="2">{t("points")}</IonCol>
          </IonRow>
          {installations!.map((item) => {
            return (
              <IonRow key={item.id} className="body-table-row points-table-row">
                <IonCol size="6">{item.productNameDescription}</IonCol>
                <IonCol size="4">
                  {moment(item.date).format("DD/MM/YYYY")}
                </IonCol>
                <IonCol size="2" className="points-col">
                  {item.productPoints}
                </IonCol>
              </IonRow>
            );
          })}
        </IonGrid>
        <FooterDesktop />
      </IonPage>
    </>
  );
};

export default MyPoints;
