import React from "react";

type BranchContextType = {
    data: any,
    dispatch: any
}

const BranchContext = React.createContext<BranchContextType | null>(null);

export { BranchContext };
