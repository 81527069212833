import {
  IonPage,
  IonToolbar,
  IonSegment,
  IonSegmentButton,
  IonRow,
  IonThumbnail,
  IonGrid,
  IonCol,
} from "@ionic/react";
import React, { useState } from "react";
import SignUpComponent from "../components/SignUpComponent";
import LoginComponent from "../components/LoginComponent";
import { useTranslation } from "react-i18next";

interface Login {
  location: any;
}

const Login: React.FC<Login> = ({ location }) => {
  // console.log(location);

  const { t, i18n } = useTranslation();
  const [imageLoading, setImageLoading] = useState(true);
  const [showForm, setShowForm] = useState<string>(
    location && location.state && location.state.signUp ? "sign_up" : "login"
  );

  const windowWidth = window.innerWidth;
  setTimeout(() => {
    setImageLoading(false);
  }, 4000);

  if (imageLoading && windowWidth <= 575) {
    return (
      <IonThumbnail className="splash-wrapper">
        <img src="img/splash.jpg" alt="" />
      </IonThumbnail>
    );
  }

  return (
    <IonPage
      className="main-page-content login-page"
      dir={i18n.language === "IL" ? "rtl" : "ltr"}
    >
      <IonGrid className="container login-content">
        <IonRow>
          <IonCol>
            <IonToolbar className="custom-toolbar">
              <IonSegment value={showForm} mode="md">
                <IonSegmentButton
                  mode="md"
                  value="login"
                  onClick={() => setShowForm("login")}
                  className="custom-toolbar-link"
                >
                  {t("login")}
                </IonSegmentButton>
                <IonSegmentButton
                  mode="md"
                  value="sign_up"
                  onClick={() => setShowForm("sign_up")}
                  className="custom-toolbar-link"
                >
                  {t("sign_up")}
                </IonSegmentButton>
              </IonSegment>
            </IonToolbar>
            {showForm === "login" ? <LoginComponent /> : <SignUpComponent />}
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonPage>
  );
};

export default Login;
