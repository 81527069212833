import React, { useState, useEffect } from "react";
import {
  IonHeader,
  IonMenu,
  IonMenuButton,
  IonGrid,
  IonRow,
  IonCol,
  IonPopover,
  IonButton,
  IonList,
  IonItem,
  IonAvatar,
  IonIcon,
  isPlatform,
} from "@ionic/react";
import { NavLink, useHistory } from "react-router-dom";

import LoggedMobileMenu from "./LoggedMobileMenu";
import ChangeLangList from "./ChangeLangList";
import ChangeBranchList from "./ChangeBranchList";
import UnloggedMobileMenu from "./UnloggedMobileMenu";
import { personCircleOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";

interface ContainerProps {}

const Header: React.FC<ContainerProps> = () => {
  const { t, i18n } = useTranslation();
  const [showLangPopover, setShowLangPopover] = useState(false);
  const [showBranchPopover, setShowBranchPopover] = useState(false);
  const [showUserPopover, setShowUserPopover] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [userName, setUserName] = useState("");

  const history = useHistory();

  const isLog = () => {
    if (localStorage.getItem("token")) {
      setIsAuth(true);
    } else {
      setIsAuth(false);
    }
  };
  useEffect(() => {
    isLog();
  }, []);

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("user")!);
    if (user) setUserName(user.name);
  }, []);

  if (history.location.pathname.includes("/recommended_installers_share")) {
    return null;
  }
  return (
    <>
      <IonHeader
        className={
          isPlatform("iphone")
            ? "custom-header-ios custom-header"
            : "custom-header"
        }
        dir={i18n.language === "IL" ? "rtl" : "ltr"}
      >
        <IonGrid className="mobile-header">
          <IonGrid className="container">
            {/* <img src="img/logo-tyco.png" alt="" /> */}
            {/* <NavLink to="/home" className="header-link"> */}
            <span className="company-title">Visonic Club</span>
            {/* </NavLink> */}
            <IonMenuButton
              id="mobile-menu"
              className="burger-button"
            ></IonMenuButton>
          </IonGrid>
        </IonGrid>
      </IonHeader>
      <IonHeader
        dir={i18n.language === "IL" ? "rtl" : "ltr"}
        className={
          isAuth
            ? "desktop-header logged-header"
            : "desktop-header unlogged-header"
        }
      >
        <IonGrid className="header-top">
          <IonGrid className="container">
            <IonRow>
              <IonCol
                className="dc header-top-block online-catalog-block"
                size-md="3"
                size-lg="3"
              >
                <a
                  href="http://catalog.visonic.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="catalog header-top-button"
                >
                  Online Catalog
                </a>
              </IonCol>
              {!isAuth ? (
                <IonCol
                  size-md="3"
                  size-lg="3"
                  className="dc header-top-block change-branch-block"
                >
                  <IonButton
                    className="header-top-button branches-header"
                    onClick={() => setShowBranchPopover(true)}
                  >
                    Change Branch
                    {/* {t("change_branch")} */}
                  </IonButton>
                  <IonPopover
                    isOpen={showBranchPopover}
                    cssClass="branch-popover"
                    onDidDismiss={(e) => setShowBranchPopover(false)}
                  >
                    <ChangeBranchList
                      hideBranchList={() => setShowBranchPopover(false)}
                    />
                  </IonPopover>
                </IonCol>
              ) : (
                <IonCol
                  size-md="3"
                  size-lg="3"
                  className="dc header-top-block desktop-header-user-block"
                >
                  <IonButton
                    className="header-top-button desktop-user-header"
                    onClick={() => setShowUserPopover(true)}
                  >
                    <IonAvatar className="user-photo">
                      <IonIcon icon={personCircleOutline}></IonIcon>
                      {/* <img src="https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y" /> */}
                    </IonAvatar>
                    {t("hello")},&nbsp;
                    <span className="header-user-name">{userName}</span>
                  </IonButton>
                  <IonPopover
                    isOpen={showUserPopover}
                    cssClass="user-popover"
                    onDidDismiss={(e) => setShowUserPopover(false)}
                  >
                    <IonGrid className="header-user-popover">
                      <IonList className="header-user-list">
                        <IonItem>
                          <IonButton
                            className="user-popopver-button"
                            fill="clear"
                            onClick={() => {
                              history.push("update_profile");
                              setShowUserPopover(false);
                            }}
                          >
                            <NavLink
                              to="/update_profile"
                              onClick={() => setShowUserPopover(false)}
                            >
                              {t("update_profile")}
                            </NavLink>
                          </IonButton>
                        </IonItem>
                        <IonItem>
                          <IonButton
                            className="user-popopver-button"
                            fill="clear"
                            onClick={() => {
                              setShowUserPopover(false);
                              localStorage.clear();
                              window.location.replace("/home_unlogged");
                            }}
                          >
                            {t("sign_out")}
                          </IonButton>
                        </IonItem>
                      </IonList>
                    </IonGrid>
                  </IonPopover>
                </IonCol>
              )}

              <IonCol size-md="3" size-lg="3" className="dc header-top-block">
                <IonButton
                  className="header-top-button"
                  onClick={() => setShowLangPopover(true)}
                  // onMouseEnter={() => setShowPopover(true)}
                  // onMouseLeave={() => setShowPopover(false)}
                >
                  {t("languages")}&nbsp;
                  <span
                    className="curr-lang"
                    style={{ textDecoration: "underline", color: "#2B4B85" }}
                  >
                    {i18n.language === "UK" ? "EN" : i18n.language}
                  </span>
                  <i className="arrow-down"></i>
                </IonButton>
                <IonPopover
                  isOpen={showLangPopover}
                  cssClass="lang-popover"
                  onDidDismiss={(e) => setShowLangPopover(false)}
                >
                  <ChangeLangList
                    closeLangModal={() => setShowLangPopover(false)}
                  />
                </IonPopover>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonGrid>

        <IonGrid className="header-middle">
          <IonGrid className="container">
            <IonRow>
              <IonCol size-sm="6" className="logo-col">
                <NavLink replace to="/home">
                  <img src="img/logo-tyco.png" alt="" />
                </NavLink>
              </IonCol>
              <IonCol
                size-sm="6"
                className={
                  i18n.language === "IL"
                    ? "logo-col position-left"
                    : "logo-col position-right"
                }
              >
                <NavLink replace to="/home">
                  <img src="img/logo-visonic.png" alt="" />
                </NavLink>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonGrid>
        {isAuth ? (
          <IonGrid className="header-bottom header-main-nav-section">
            <IonGrid className="container">
              <IonList className="header-main-nav-list">
                <IonItem>
                  <NavLink replace to="/home">
                    {t("dashboard")}
                  </NavLink>
                </IonItem>
                <IonItem>
                  <NavLink replace to="/my_points">
                    {t("my_points")}
                  </NavLink>
                </IonItem>
                <IonItem>
                  <NavLink replace to="/redeem">
                    {t("redeem")}
                  </NavLink>
                </IonItem>
                <IonItem>
                  <NavLink replace to="/knowledge_center">
                    {t("knowledge_center")}
                  </NavLink>
                </IonItem>
                <IonItem>
                  <NavLink replace to="/support">
                    {t("support")}
                  </NavLink>
                </IonItem>
              </IonList>
            </IonGrid>
          </IonGrid>
        ) : null}
      </IonHeader>
      {isAuth ? (
        <IonMenu
          side={i18n.language === "IL" ? "start" : "end"}
          contentId="mobile-menu"
          className="mobile-menu logged-mobile-menu"
        >
          <LoggedMobileMenu />
        </IonMenu>
      ) : (
        <IonMenu
          side={i18n.language === "IL" ? "start" : "end"}
          contentId="mobile-menu"
          className="mobile-menu unlogged-mobile-menu"
        >
          <UnloggedMobileMenu />
        </IonMenu>
      )}
    </>
  );
};

export default Header;
