import React, { useState, useEffect } from "react";
import { IonButton, IonIcon, IonHeader } from "@ionic/react";
import { useHistory } from "react-router-dom";
import { chevronBack } from "ionicons/icons";
import { useTranslation } from "react-i18next";

const BackToolbar = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  return (
    <IonHeader className="back-header">
      <IonButton
        fill="clear"
        className="back-button"
        onClick={() => history.goBack()}
      >
        <IonIcon icon={chevronBack}></IonIcon>
        {t('back')}
      </IonButton>
    </IonHeader>
  );
};

export default BackToolbar;