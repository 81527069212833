import React, { useState, useEffect, useContext } from "react";
import { IonRow, IonCol, IonText } from "@ionic/react";
import { useTranslation } from "react-i18next";

import { BranchContext } from "../context/BranchContext";

const PersonalMessage = () => {
  const [userId, setUserId] = useState("");
  const [passed, setPassed] = useState();
  const [userName, setUserName] = useState("");
  const { i18n, t } = useTranslation();
  const [loaded, setLoaded] = useState(false);
  const { data, dispatch } = useContext(BranchContext)!;
  const [link, setLink] = useState("");

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user")!);
    if (user) {
      setUserId(user.id);
      setPassed(user.testPassed);
      setUserName(user.name);
      setLoaded(true);
    }
  }, []);

  useEffect(() => {
    switch (data.branch) {
      case "IL":
        setLink("https://hikedigital.typeform.com/to/sIvF5Q?id=");
        break;
      case "UK":
        setLink("https://hikedigital.typeform.com/to/pgQKYf?id=");
        break;
      case "SP":
        setLink("https://hikedigital.typeform.com/to/mHRyUW?id=");
        break;
      case "FR":
        setLink("https://hikedigital.typeform.com/to/zC0q7a?id=");
        break;
      default:
        setLink("https://hikedigital.typeform.com/to/rZEjdJ?id=");
        break;
    }
  }, [data]);

  return (
    <>
      {loaded && (
        <IonRow className="personal-msg-row">
          <IonCol size="12" className="ion-margin-bottom">
            {i18n.language !== "PT" && data.branch !== "PT" ? (
              passed ? (
                <IonText className="personal-msg">
                  <p>{t("congratulations")}</p>
                </IonText>
              ) : (
                <IonText className="personal-msg">
                  <p className="font-bold">
                    {t("hi")}&nbsp;
                    <span className="personal-name">{userName}</span>
                    ,&nbsp;
                    {t("welcome")}
                  </p>
                  {t("you_need_to_pass")}&nbsp;
                  <a
                    href={`${link}${userId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("click_here")}
                  </a>
                </IonText>
              )
            ) : (
              <IonText className="personal-msg">
                <p className="font-bold">
                  {t("hi")}&nbsp;
                  <span className="personal-name">{userName}</span>
                  ,&nbsp;
                  {t("welcome")}
                </p>
              </IonText>
            )}
            {}
          </IonCol>
        </IonRow>
      )}
    </>
  );
};

export default PersonalMessage;
