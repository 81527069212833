import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import moment from "moment";

import {
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonToolbar,
  IonTitle,
  IonText,
  IonIcon,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonCardSubtitle,
} from "@ionic/react";
import { addCircle, call, removeCircle, mailOutline } from "ionicons/icons";
import { HashLink as Link } from "react-router-hash-link";
import { useHistory, useParams } from "react-router-dom";

import FooterDesktop from "../components/FooterDesktop";
import GoogleMapReact from "google-map-react";
import Marker from "../components/Marker";
import { BranchContext } from "../context/BranchContext";
import { useTranslation } from "react-i18next";

import api from "../api";

const RecommendedInstallersFrame = () => {
  const [installers, setInstallers] = useState<any>([]);
  const [currentBranch, setCurrentBranch] = useState<any>('');
  const [installerType, setInstallerType] = useState("Platinum+Diamond");
  const [displayedInstallers, setDisplayedInstallers] = useState([]);
  const [defaultCenter, setDefaultCenter] = useState({
    lat: 21.95,
    lng: 12.33,
  });
  const [zoom, setZoom] = useState(6);
  const [displayInfo, setDisplayInfo] = useState(false);
  const [dispalyedId, setDisplayedId] = useState("");
  const { data, dispatch } = useContext(BranchContext)!;
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const UPDATE_BRANCH = "UPDATE_BRANCH";

  let { branch } = useParams();

  function getConfig() {
    return {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
  }

  useEffect(() => {
    const getTypeInstaller = () => {
      switch (installerType) {
        case "Gold":
          getInstallers("Gold");
          break;
        case "Platinum+Diamond":
          getInstallers("Platinum+Diamond");
          break;
        default:
          break;
      }
    };

    getTypeInstaller();
  }, [installers, installerType]);

  const getInstallers = (type: any) => {
    let result: any = [];
    if (installers && installers.length > 0) {
      if (
        !history.location.pathname.includes("/recommended_installers_share")
      ) {
        setDefaultCenter({
          lat: installers[0].addressLatitude,
          lng: installers[0].addressLongitute,
        });
      }
      installers.forEach((installer: any) => {
        if (
          installer.addressLatitude &&
          installer.addressLongitute &&
          installer.branch === data.branch
        ){
          if (type === 'Gold' && installer.memberLevel === 'Gold') {
            result.push(installer);
          }
          if (type !== 'Gold' && (installer.memberLevel === 'Platinum' || installer.memberLevel === 'Diamond')) result.push(installer);
        }
      });
    }
    setDisplayedInstallers(result);
  };

  useEffect(() => {
    const fetchInstallers = () => {
      axios.get(api.getInstallers, getConfig()).then((res) => {
        // console.log(res);
        if (res.data.success && res.data.installers) {
          localStorage.setItem(
            "installers",
            JSON.stringify(res.data.installers)
          );
          localStorage.setItem(
            "installersUpdateDate",
            JSON.stringify(moment())
          );

          setInstallers(res.data.installers);
        }
      });
    };

    const getTypeInstaller = () => {
      switch (installerType) {
        case "Gold":
          getInstallers("Gold");
          break;
        case "Platinum+Diamond":
          getInstallers("Platinum+Diamond");
          break;
        default:
          break;
      }
    };
    let installersUpdateDate = JSON.parse(
      localStorage.getItem("installersUpdateDate")!
    );

    if (
      installersUpdateDate &&
      moment().subtract(1, "months") > installersUpdateDate
    ) {
      fetchInstallers();
    }

    let installers = JSON.parse(localStorage.getItem("installers")!);
    if (installers && installers.length > 0) {
      setInstallers(installers);
    } else fetchInstallers();

    getTypeInstaller();
    
  }, [data]);

  const getAddressLine: any = (installer: any) => {
    let address = "";
    if (installer.addressLine1) address += installer.addressLine1;
    if (installer.addressLine2) address += ", " + installer.addressLine2;
    if (installer.city) address += ", " + installer.city;
    if (installer.state) address += ", " + installer.state;
    if (installer.country) address += ", " + installer.country;
    if (installer.postalCode) address += ", " + installer.postalCode;
    return address;
  };

  useEffect(() => {
    if (branch) {
      dispatch({
        type: UPDATE_BRANCH,
        branch: branch,
      });
      // console.log("branch", branch);
      switch (branch) {
        case "IL":
          setDefaultCenter({
            lat: 32.0853,
            lng: 34.7818,
          });
          break;
        case "FR":
          setDefaultCenter({
            lat: 46.227638,
            lng: 2.213749,
          });
          break;
        case "SP":
          setDefaultCenter({
            lat: 40.463669,
            lng: -3.74922,
          });
          break;
        default:
          setDefaultCenter({
            lat: 51.3498808,
            lng: -2.9394152,
          });
          break;
      }
      // console.log(defaultCenter);
      i18n.changeLanguage(branch);
      setCurrentBranch(branch)
    }
  }, [branch]);
  useEffect(() => {
    // console.log("defaultcenter", defaultCenter);
  }, [defaultCenter]);

  const getCardImage = (memberLevel: string) => {
    let img = "";

    if (memberLevel === "Gold")
      img = "img/cards/UK/VClub-Cards-Valid-Gold-EN.png";
    else if (memberLevel === "Diamond")
      img = "img/cards/UK/VisoniClub_diamond_card_en_without_name.jpg";
    else if (memberLevel === "Platinum")
      img = "img/cards/UK/VClub-Cards-Valid-Platinum-EN.png";

    return img;
  };
  console.log('branch ->>.,', currentBranch)

  return (
    <IonPage dir={i18n.language === "IL" ? "rtl" : "ltr"}>
      <IonGrid className="container">
        <IonRow>
          <IonCol>
            {!history.location.pathname.includes(
              "/recommended_installers_share"
            ) ? (
              <IonToolbar className="title-toolbar">
                <IonTitle className="page-title">
                  {t("recommended_installers")}
                </IonTitle>
              </IonToolbar>
            ) : null}

            <IonGrid>
              <IonRow className="ion-text-center">
                <IonCol>
                  <div className="level-form-wrapper">
                    <form
                      id="level-form"
                      method="get"
                      className="level-form"
                      style={{ marginBottom: "10px" }}
                    >
                      <select
                        id="level"
                        name="level"
                        onChange={(e) => setInstallerType(e.target.value)}
                        value={installerType}
                      >
                        <option value="Gold">Gold Installers</option>
                        <option value="Platinum+Diamond">Platinum+Diamond Installers</option>
                      </select>
                    </form>
                    <div className="installers-map-wrapper">
                      <GoogleMapReact
                        bootstrapURLKeys={{
                          key: "AIzaSyBewfRqdQ6NjmgsoANEb0ozDlT5wzmmBec",
                        }}
                        center={defaultCenter}
                        zoom={zoom}
                        onZoomAnimationEnd={(e) => setZoom(e)}
                      >
                        {displayedInstallers &&
                          displayedInstallers.map((installer: any) => {
                            return (
                              <Marker
                                key={installer.id}
                                lat={installer.addressLatitude}
                                lng={installer.addressLongitute}
                                text={installer.name}
                              />
                            );
                          })}
                      </GoogleMapReact>
                    </div>
                    {displayedInstallers &&
                      displayedInstallers.map((installer: any) => {
                        return (
                          <IonCard
                            key={installer.id}
                            className="installer-information"
                          >
                            <IonCardTitle
                              color="primary"
                              className="installers-card-title"
                            >
                              <div className="installers-card-title-div">
                                <IonText>
                                  {installer.name}, {installer.accountName}{" "}
                                </IonText>
                                <img
                                  src={getCardImage(installer.memberLevel)}
                                  alt="card"
                                  className="installer-card-img"
                                />
                              </div>
                              {displayInfo && dispalyedId === installer.id ? (
                                <IonIcon
                                  icon={removeCircle}
                                  className="circle-icon"
                                  onClick={() => {
                                    setDisplayInfo(false);
                                    setDisplayedId("");
                                  }}
                                ></IonIcon>
                              ) : (
                                <IonIcon
                                  icon={addCircle}
                                  className="circle-icon"
                                  onClick={() => {
                                    setDisplayInfo(true);
                                    setDisplayedId(installer.id);
                                  }}
                                ></IonIcon>
                              )}
                            </IonCardTitle>
                            <IonCardContent>
                              <IonCardSubtitle className="installers-card-sub-main">
                                <IonText
                                  className="installers-address"
                                  color="medium"
                                  onClick={() => {
                                    setDefaultCenter({
                                      lat: installer.addressLatitude,
                                      lng: installer.addressLongitute,
                                    });
                                    setZoom(13);
                                  }}
                                >
                                  <Link
                                    smooth
                                    to="#level-form"
                                    className="navigation-link"
                                  >
                                    {getAddressLine(installer)}
                                  </Link>
                                </IonText>
                                <br />
                                {currentBranch !== "IL" ? (
                                  <IonText className="installers-member-level">
                                    {installer.memberLevel} Member
                                  </IonText>
                                ) : (
                                  <IonText className="installers-member-level">
                                    {installer.memberLevelHeb}
                                  </IonText>
                                )}
                              </IonCardSubtitle>

                              {displayInfo && dispalyedId === installer.id && (
                                <IonCardSubtitle>
                                  <div className="installers-card-phone">
                                    <IonIcon
                                      icon={call}
                                      className="installers-icon"
                                    />
                                    <IonText color="medium">
                                      {installer.phone}
                                    </IonText>
                                  </div>
                                  <div className="installers-card-mail">
                                    <IonIcon
                                      icon={mailOutline}
                                      className="installers-icon"
                                    />
                                    <a
                                      href={`http://online2013.net/dealersSite/NewLead.aspx?branch=${data.branch}&id=${installer.id}`}
                                      target="_blank"
                                    >
                                      {t("contact")} {">"}
                                    </a>
                                  </div>
                                </IonCardSubtitle>
                              )}
                            </IonCardContent>
                          </IonCard>
                        );
                      })}
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCol>
        </IonRow>
      </IonGrid>
      {!history.location.pathname.includes("/recommended_installers_share") ? (
        <FooterDesktop />
      ) : null}
    </IonPage>
  );
};

export default RecommendedInstallersFrame;
