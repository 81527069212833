import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import {
  IonPage,
  IonCard,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonSpinner,
  IonText,
  IonButton,
  IonImg,
  IonThumbnail,
  IonGrid,
  IonToolbar,
  IonTitle,
} from "@ionic/react";

import api from "../api";
import helper from "../helper";
import MyModal from "../components/MyModal";
import { useTranslation } from "react-i18next";
import PersonalMessage from "../components/PersonalMessage";
import FooterDesktop from "../components/FooterDesktop";

const AvailableGifts: React.FC = () => {
  interface Gift {
    id: string;
    name: string;
    imageUrl: string;
    imageUrlSecure: string;
    description: string;
    points: number;
  }

  const [items, setItems] = useState(Array<Gift>());

  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);

  const [showedItem, setShowedItem] = useState<number>();

  const [showModal, setShowModal] = useState(false);
  const [points, setPoints] = useState<number>(0);

  let totalPages: any = 0;

  function getConfig(limit: number, page: number) {
    return {
      params: {
        limit: limit,
        page: page,
      },
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
  }

  const observer: any = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      // console.log("Callback");
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setCurrentPage((prevPageNumber) => prevPageNumber + 1);
        }
      });

      if (node) observer.current.observe(node);
      console.log("cur page", currentPage);
    },

    [loading, hasMore]
  );
  useEffect(() => {
    document.title = t("page_titles.redeem");
  }, []);

  useEffect(() => {
    setLoading(true);
    setPoints(JSON.parse(localStorage.getItem("user")!).scoreBalance);
    try {
      axios
        .get(api.getAvailableGifts, getConfig(4, currentPage))
        .then((res) => {
          if (res && res.data.success && res.data.results.length > 0) {
            totalPages = res.data.totalPages;
            console.log("totalPages-->", totalPages);

            setItems(items.concat(res.data.results));
            setHasMore(res.data.results.length > 0);

            setHasMore(
              (currentPage - 1) * 5 + res.data.results.length <
                res.data.totalRecords
            );
            setLoading(false);
          }
        });
    } catch (error) {
      helper.showAlert(false, error.message);
    }
  }, [currentPage]);

  function recalculatePoints(points: number) {
    setPoints(points);
  }

  return (
    <>
      <IonPage
        className="main-page-content"
        dir={i18n.language === "IL" ? "rtl" : "ltr"}
      >
        <IonGrid className="container gifts-container">
          <PersonalMessage />
          <IonToolbar className="title-toolbar">
            <IonTitle className="page-title">{t("redeem")}</IonTitle>
          </IonToolbar>
          {items.map((item, index) => {
            return (
              <IonCard
                button={true}
                key={item.id}
                ref={items.length === index + 1 ? lastBookElementRef : null}
                onClick={() => setShowedItem(index)}
                className="card"
              >
                <div className="card-block">
                  <IonThumbnail slot="start" className="card-img-wrapper">
                    <IonImg src={item.imageUrlSecure} />
                  </IonThumbnail>
                  <IonCardContent className="card-content-wrapper">
                    <IonCardTitle className="card-title">
                      {item.name}
                    </IonCardTitle>
                    <IonCardSubtitle className="card-description">
                      {item.points}&nbsp;{t("points")}
                    </IonCardSubtitle>
                  </IonCardContent>
                  <div
                    className="btn-wrapper card-desktop-button"
                    style={
                      i18n.language === "IL"
                        ? { marginRight: "auto" }
                        : { marginLeft: "auto" }
                    }
                  >
                    <IonButton
                      className="custom-btn"
                      disabled={item.points > points}
                      onClick={() => setShowModal(true)}
                    >
                      {t("buy_now")}
                    </IonButton>
                  </div>
                </div>
                {showedItem === index ? (
                  <>
                    <div className="details-wrapper">
                      <IonText>
                        <p>{item.description}</p>
                      </IonText>
                      <div className="btn-wrapper card-mobile-button">
                        <IonButton
                          className="custom-btn"
                          disabled={item.points > points}
                          onClick={() => setShowModal(true)}
                        >
                          {t("buy_now")}
                        </IonButton>
                      </div>
                    </div>
                    <MyModal
                      showModal={showModal}
                      item={item}
                      hideModal={() => setShowModal(false)}
                      recalculatePoints={(points: number) =>
                        recalculatePoints(points)
                      }
                    />
                  </>
                ) : (
                  <></>
                )}
              </IonCard>
            );
          })}
          {loading && <IonSpinner className="spinner" name="crescent" />}
        </IonGrid>
        <FooterDesktop />
      </IonPage>
    </>
  );
};

export default AvailableGifts;
