import React, { Suspense, useState, useEffect, useReducer } from "react";
import { IonContent, IonApp, isPlatform } from "@ionic/react";

import { BrowserRouter as Router, Switch } from "react-router-dom";

import Header from "./components/Header";
import Routes from "./components/Routes";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
/* custom css */
import "./css/fonts.css";
import "./css/vars-custom.css";
import "./css/custom.css";
import "./css/dir.css";
import "./css/media.css";
import FooterMobile from "./components/FooterMobile";

import { IonSpinner } from "@ionic/react";
import { ScreenOrientation } from "@ionic-native/screen-orientation";
import { BranchContext } from "./context/BranchContext";

// loading component for suspence fallback
const Loader = () => (
  <div className="spinner-wrapper">
    <IonSpinner name="bubbles" />
  </div>
);

const initialState = {
  branch: "",
};

const UPDATE_BRANCH = "UPDATE_BRANCH";

function reducer(state: any, action: any) {
  switch (action.type) {
    case UPDATE_BRANCH:
      return {
        ...state,
        branch: action.branch,
      };
    default:
      return initialState;
  }
}

// here app catches the suspense from page in case translations are not yet loaded
const App: React.FC = () => {
  const [data, dispatch] = useReducer(reducer, initialState);
  const [isAuth, setIsAuth] = useState(false);

  const isLog = () => {
    if (localStorage.getItem("token")) {
      setIsAuth(true);
    } else {
      setIsAuth(false);
    }
  };

  useEffect(() => {
    if (isPlatform("mobile")) {
      ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT);
    }
    const branch = localStorage.getItem("branch");
    if (!data.branch)
      dispatch({
        type: UPDATE_BRANCH,
        branch: branch ? branch : "UK",
      });

    isLog();
  }, []);

  return (
    <BranchContext.Provider value={{ data, dispatch }}>
      <IonApp className="app-wrapper">
        <Router>
          <Suspense fallback={<Loader />}>
            <Header />
            <IonContent className="page-content">
              <Switch>
                <Routes />
              </Switch>
            </IonContent>
            {isAuth ? <FooterMobile /> : null}
          </Suspense>
        </Router>
      </IonApp>
    </BranchContext.Provider>
  );
};

export default App;
