import {
  IonInput,
  IonButton,
  IonPage,
  IonItem,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
} from "@ionic/react";
import React, { useState, useEffect } from "react";

import api from "../api";
import axios from "axios";
import helper from "../helper";
import { loadingController } from "@ionic/core";
import { useHistory } from "react-router-dom";
import BackToolbar from "../components/BackToolbar";
import { useTranslation } from "react-i18next";

interface ContainerProps {}

const ChangePassword: React.FC<ContainerProps> = () => {
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [oldPassword, setOldPassword] = useState<string>("");
  const [newPassword1, setNewPassword1] = useState<string>("");
  const [newPassword2, setNewPassword2] = useState<string>("");
  const [userId, setUserId] = useState<string>("");
  const { t, i18n } = useTranslation();

  const history = useHistory();

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("user")!);
    setCurrentPassword(user.password);
    setUserId(user.id);
  }, []);

  function getConfig() {
    return {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
  }

  const user = {
    password: newPassword1,
  };

  const submit = async () => {
    if (newPassword1 !== newPassword2) {
      setNewPassword1("");
      setNewPassword2("");
      helper.showAlert(false, "Passwords don't match");
      return;
    }
    const loading = await loadingController.create({
      message: "Please wait...",
    });
    loading.present();
    axios.put(api.updateUser + userId, user, getConfig()).then((res) => {
      loading.dismiss().then(() => {
        if (!res.data.success) {
          helper.showAlert(res.data.success, res.data.errorMessage);
        } else {
          history.push("/settings");
          localStorage.setItem("user", JSON.stringify(res.data.user));
          helper.showToast("Password was changed successfully", "top");
        }
      });

      console.log(res);
    });
  };

  return (
    <IonPage
      dir={i18n.language === "IL" ? "rtl" : "ltr"}
      className="main-page-content change=password-page"
    >
      <BackToolbar />
      <IonGrid className="container">
        <IonGrid className="forgot-pass-content">
          <IonRow>
            <IonCol>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  submit();
                }}
              >
                <IonLabel>{t("enter_current_password")}</IonLabel>
                <IonItem className="field-wrapper">
                  <IonInput
                    type="password"
                    value={oldPassword}
                    placeholder={t("current_password")}
                    onIonChange={(e) => setOldPassword(e.detail.value!)}
                    onIonBlur={() => {
                      if (currentPassword !== oldPassword) {
                        setOldPassword("");
                        setNewPassword1("");
                        setNewPassword2("");
                        helper.showAlert(
                          false,
                          "Current password is incorrect. Please try again"
                        );
                      }
                    }}
                    className="custom-input custom-field"
                  ></IonInput>
                </IonItem>
                <IonLabel>{t("enter_new_password")}</IonLabel>
                <IonItem className="field-wrapper">
                  <IonInput
                    type="password"
                    value={newPassword1}
                    placeholder={t("new_password")}
                    onIonChange={(e) => setNewPassword1(e.detail.value!)}
                    className="custom-input custom-field"
                  ></IonInput>
                </IonItem>
                <IonLabel>{t("repeat_new_password")}</IonLabel>
                <IonItem className="field-wrapper">
                  <IonInput
                    type="password"
                    value={newPassword2}
                    placeholder={t("new_password")}
                    onIonChange={(e) => setNewPassword2(e.detail.value!)}
                    className="custom-input custom-field"
                  ></IonInput>
                </IonItem>
                <IonButton
                  fill="solid"
                  shape="round"
                  type="submit"
                  className="custom-btn"
                >
                  {t("change_password")}
                </IonButton>
              </form>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonGrid>
    </IonPage>
  );
};

export default ChangePassword;
