import React from "react";
import { isPlatform } from "@ionic/react";
import { Redirect, Route, Switch } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import Home from "../pages/Home";
import MyPoints from "../pages/MyPoints";
import Login from "../pages/Login";
import AvailableGifts from "../pages/AvailableGifts";
import Scanner from "../pages/Scanner";
import ForgotPassword from "../pages/ForgotPassword";
import SupportPage from "../pages/SupportPage";
import VerifyBarcode from "../pages/VerifyBarcode";
import KnowledgeCenter from "../pages/KnowledgeCenter";
import Settings from "../pages/Settings";
import UpdateProfile from "../pages/UpdateProfile";
import ChangePassword from "../pages/ChangePassword";
import NotFoundPage from "../pages/NotFoundPage";
import NoConnection from "../pages/NoConnection";
import ChangeBranch from "../pages/ChangeBranch";
import Languages from "../pages/Languages";
import HomeUnlogged from "../pages/HomeUnlogged";
import RecommendedInstallers from "../pages/RecommendedInstallers";
import RecommendedInstallersFrame from "../pages/RecommendationInstallersFrame";
import Authorization from "../pages/Authorization";

interface ContainerProps {}

const Routes: React.FC<ContainerProps> = () => {
  const isLog = () => {
    if (localStorage.getItem("token")) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Switch>
      <Route
        exact
        path="/"
        render={() => <Redirect to={isLog() ? "/home" : "/home_unlogged"} />}
      />
      <Route path="/login" component={Login} exact={true} />
      <Route path="/authCaptcha" component={Authorization} exact={true} />
      <PrivateRoute path="/scanner" component={Scanner} exact={true} />
      <PrivateRoute path="/redeem" component={AvailableGifts} exact={true} />
      <Route path="/forgot_password" component={ForgotPassword} exact={true} />
      <PrivateRoute path="/support" component={SupportPage} exact={false} />
      <PrivateRoute
        path="/verify_barcode"
        component={VerifyBarcode}
        exact={true}
      />
      <PrivateRoute path="/home" component={Home} exact={true} />
      <PrivateRoute path="/my_points" component={MyPoints} exact={true} />
      <PrivateRoute
        path="/knowledge_center"
        component={KnowledgeCenter}
        exact={true}
      />
      <PrivateRoute path="/settings" component={Settings} exact={true} />
      <PrivateRoute
        path="/update_profile"
        component={UpdateProfile}
        exact={true}
      />
      <PrivateRoute
        path="/change_password"
        component={ChangePassword}
        exact={true}
      />
      <Route path="/change_branch" component={ChangeBranch} exact={true} />
      <PrivateRoute path="/languages" component={Languages} exact={true} />
      <Route
        path="/home_unlogged"
        render={() =>
          isPlatform("mobile") ||
          isPlatform("iphone") ||
          isPlatform("android")
            ? Login
            : isLog()
            ? Home
            : HomeUnlogged
        }
        component={
          isPlatform("mobile") ||
          isPlatform("iphone") ||
          isPlatform("android")
            ? Login
            : isLog()
            ? Home
            : HomeUnlogged
        }
        exact={true}
      />
      <Route
        path="/recommended_installers"
        component={RecommendedInstallers}
        exact={true}
      />
      <Route
        path="/recommended_installers_share/:branch?"
        component={RecommendedInstallersFrame}
        exact={true}
      />
      <Route component={NotFoundPage} />
      <Route component={NoConnection} />
    </Switch>
  );
};

export default Routes;
